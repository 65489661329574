import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { PurlGuard } from './_guards/purl.guard';
import { LoadAuth } from './state/auth/auth.actions';
import { AuthEffects } from './state/auth/auth.effects';
import * as auth from './state/auth/auth.reducer';
import { AuthService } from './state/auth/auth.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    StoreModule.forFeature(auth.featureName, auth.reducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [AuthService, PurlGuard]
})
export class MedAuthorizationLibModule {
  constructor(store: Store<any>) {
    store.dispatch(new LoadAuth());
  }
}
