import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import * as auth from '../state/auth/auth.reducer';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { JwtManager } from '../jwtmanager/jwt-manager.service';
import { GetToken } from '../state/auth/auth.actions';

@Injectable()
export class PurlGuard implements CanActivate {
  private JwtManager = new JwtManager();
  constructor(private router: Router, private store: Store<any>) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    const purl = route.params['purl'];

    if (purl === undefined) {
      return false;
    }

    return combineLatest([
      this.store.select(auth.tokenValidationCheck),
      this.store.select(auth.isAuthenticatedForPurl(purl))
    ]).pipe(
      tap(([isvalid, auth]) => {
        if (!auth) {
          this.store.dispatch(new GetToken(purl))
        }
      }),
      map(([isvalid, auth]) => isvalid && auth)
    );
  }
}
