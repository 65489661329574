<mat-card>
  <mat-card-header>
    <mat-card-title><b>{{ Label }}</b></mat-card-title>
    <div *ngIf="infoImageUrl" class="tooltip">
      <mat-icon style="color:#0053a0;font-size: 14px; margin-left: -15px;">info</mat-icon>
      <span class="tooltiptext"
        ><img [src]="infoImageUrl" />
      </span>
    </div>
  </mat-card-header>

  <mat-card-content>
    <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->
    <div class="spinner-wrapper" *ngIf="isLoading">
      <mat-spinner></mat-spinner>
    </div>
    <img *ngIf="previewUrl" [src]="previewUrl" />
    <input type="file"  *ngIf="!disabled" (change)="fileSelected($event)" />

    <input *ngIf="value" type="hidden" [required]="required" [(ngModel)]="value" />
  </mat-card-content>

  <div class="delete-icon" *ngIf="!disabled">
    <mat-icon (click)="remove()">delete</mat-icon>
  </div>
</mat-card>
