import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'med-radiobutton',
  templateUrl: './med-radiobutton.component.html',
  styleUrls: ['./med-radiobutton.component.css']
})
export class MedRadiobuttonComponent {

  @Input() Label: string;
  @Input() Placeholder: string;
  @Input() Hint: string;
  @Input() CssClass = 'col';

  @Input() options: Array<{id: any, name: string}>;

  @Input() required = false;
  /**
   * Holds the current value of the carousel
   */
  @Input() inputValue = '';

  /**
   * Invoked when the model has been changed
   */
  @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();

}
