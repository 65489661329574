<div class="row">
  <div class="col">
    <b>{{ label }}:</b>
    <div *ngIf="infoText" class="tooltip">
      <mat-icon>info</mat-icon>
      <span class="tooltiptext">{{ infoText }} </span>
    </div>
  </div>
  <div class="col" *ngIf="!value" [innerHTML]="'-'"></div>
  <div class="col value" *ngIf="value" [innerHTML]="value"></div>
</div>
