<spaarze-layout-header
  [menuItems]="menu"
  [isAuthenticated]="false">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>
          Welkom bij SpaarZe
        </h1>
        <p>

        </p>
        <div class="row no-gutters purl">

          <div class="col-12 col-lg-5 label">
            Uw code:
          </div>
          <div class="col-12 col-lg">
            <div class="input-group">
              <input
                type="text"
                name="purl"
                placeholder="bijv. demo123"
                [(ngModel)]="purl"
                (keyup.enter)="GoToPurl()"
              />
              <button mat-ripple (click)="GoToPurl()">Open</button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-none d-md-flex col-6 codes-image"
        [style.background-image]="
          'url(../../assets/images/pictures/Hand-home.jpg)'
        "
      ></div>
    </div>
  </div>
</spaarze-layout-header>
