import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GraphQlResponse, ThrowGraphQlError } from '@x-fund-apps/med-shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ProspectSupermarket, ProspectSupermarketPurl } from './prospect-supermarket.model';

const fieldListProspect = `id
name
street
houseNumber
zipCode
city
brand
`;

const fieldListPurl = `id
purl
redirectPage
prospectSupermarket {
  ${fieldListProspect}
}
`;

const actionDataQuery = `{
    prospectSupermarketPurl {
      ${fieldListPurl}
    }
  }`;

type Variables = undefined;

@Injectable()
export class ProspectSupermarketService {
  constructor(
    @Inject('ENV') private readonly environment,
    private httpClient: HttpClient,
    @Inject('IMG') private imageService
  ) {}

  getData(purl: string): Observable<ProspectSupermarketPurl> {
    return this.httpClient
      .post(
        this.environment.urls.webshop.graph,
        { query: actionDataQuery },
        {
          headers: {
            purl,
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        tap((value: GraphQlResponse) => {
          if (!isNullOrUndefined(value.errors)) {
            ThrowGraphQlError(actionDataQuery, value.errors);
          }
        }),
        map(value => (value as GraphQlResponse).data),
        map(value => (value as GraphQlProspectSupermarketResponse).prospectSupermarketPurl)
      );
  }
}

export class GraphQlProspectSupermarketResponse {
  prospectSupermarketPurl: ProspectSupermarketPurl;
}
