import { GenericDataServiceProperties } from '@x-fund-apps/med-shared';

export class BonusProduct {
  id: number;
  name: string;
  image: string;
  supermarketId: number;
  extraCodeText: string;
  fromDate: Date;
  toDate: Date;
}

export const BONUS_PRODUCT_PROPS: GenericDataServiceProperties = {
  singlename: 'BonusProduct',
  pluralname: 'BonusProducts',
  fields: `
  id
  name
  extraCodeText
  supermarketId
  fromDate
  toDate
  image
  `
};
