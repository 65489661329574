import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Logout } from '@x-fund-apps/med-authorization-lib';
import { Router } from '@angular/router';

@Component({
  templateUrl: 'logout-prompt.component.html',
  styleUrls: ['./logout-prompt.component.scss']
})
export class LogoutPromptComponent {

  constructor(public dialogRef: MatDialogRef<LogoutPromptComponent>,
              private readonly store: Store<any>,
              @Inject(MAT_DIALOG_DATA) public data,
              private router: Router) {}

  onLogoutClick(): void {

      this.store.dispatch(new Logout());
      this.router.navigate([this.data.purl]);
      this.dialogRef.close(true);
  }

  onNoClick(): void {
      this.dialogRef.close();
  }
}
