import { GenericDataServiceProperties } from '@x-fund-apps/med-shared';

export class SzAction {
  id: number;
  name: string;
  description: string;
  logo: string;
  homeImage: string;
  price: string;
  priceCodes: number;
  hasText: boolean;
  hasCheque: boolean;
  chequeValue: string;
}

export const ACTION_PROPS: GenericDataServiceProperties = {
  singlename: 'SzAction',
  pluralname: 'SzActions',
  fields: `
    id
    name
    description
    logo
    homeImage
    price
    priceCodes
    hasText
    hasCheque
    chequeValue`
};
