<spaarze-layout-header
  [menuItems]="menu"
  [supermarket]="supermarket$ | async"
  [isAuthenticated]="false"
>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Registreer</h1>
        <p>
          Registreer u hier als ondernemer om de tegoedbonnen van {{supermarkt.name}} te kunnen innemen.
        </p>
        <p>
          <b>Let op! {{supermarkt.name}} ondersteunt enkel {{supermarkt.entrepreneursDescription}}</b>
        </p>

        <div class="row">
          <div class="col col-md-9">
            <form name="form" #f="ngForm" class="center">
              <h3>Gegevens onderneming</h3>
              <med-textfield
                name="name"
                [(ngModel)]="registerModel.name"
                Label="Naam organisatie"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="street"
                [(ngModel)]="registerModel.street"
                Label="Straat"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="houseNumber"
                [(ngModel)]="registerModel.houseNumber"
                Label="Huisnummer"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="zipCode"
                [(ngModel)]="registerModel.zipCode"
                Label="Postcode"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="city"
                [(ngModel)]="registerModel.city"
                Label="Plaats"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="kvkNumber"
                [(ngModel)]="registerModel.kvkNumber"
                Label="KvK-nummer"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="btwNumber"
                [(ngModel)]="registerModel.btwNumber"
                Label="Btw-nummer"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="iban"
                [(ngModel)]="registerModel.iban"
                Label="Rekeningnummer"
                appearance=""
                CssClass="spaarze-input"
                Hint="Wij gebruiken dit rekeningnummer alleen voor het uitbetalen van de gescande tegoedbonnen"
                required
              ></med-textfield>

              <h3>Gegevens contactpersoon</h3>

              <med-textfield
                name="contactName"
                [(ngModel)]="registerModel.contactName"
                Label="Naam contactpersoon"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="phone"
                [(ngModel)]="registerModel.phone"
                Label="Telefoonnummer"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="email"
                [(ngModel)]="registerModel.email"
                Label="E-mailadres"
                appearance=""
                CssClass="spaarze-input"
                required
                email
              ></med-textfield>

              <h3>Inlog gegevens</h3>

              <med-textfield
                name="username"
                [(ngModel)]="registerModel.userName"
                Label="Gebruikersnaam"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="password"
                [(ngModel)]="registerModel.password"
                type="password"
                Label="Wachtwoord"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="passwordRepeat"
                [(ngModel)]="registerModel.passwordRepeat"
                type="password"
                Label="Herhaal wachtwoord"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-checkbox
                name="policy"
                Label="Ja, ik accepteer de <a href='/assets/docs/AlgemeneVoorwaardenSpaarze.pdf' class='link'  target='blank'>algemene-</a> en "
                LabelLink="privacy voorwaarden"
                [(ngModel)]="termsBool"
                appearance=""
                CssClass="spaarze-checkbox"
                (linkClicked)="showTermsPopup()"
                required
              ></med-checkbox>

              <div
                [matTooltip]="isModelValid(registerModel).errors"
                [matTooltipDisabled]="isModelValid(registerModel).valid"
              >
                <button
                  class="col spaarze-login-button"
                  mat-ripple
                  (click)="onSubmit()"
                  [disabled]="
                    !f.form.valid || !isModelValid(registerModel).valid
                  "
                >
                  Registreer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="d-none d-md-flex col-6 codes-image"
        [style.background-image]="getHomeImageUrl()"
      ></div>
    </div>
  </div>
</spaarze-layout-header>
<div class="loading-screen-wrapper" *ngIf="(isLoading$ | async)">
  <div class="loading-screen-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
