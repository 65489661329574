import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { EntityDataService } from '@ngrx/data';
import { GenericDataService } from '@x-fund-apps/med-shared';
import { ACTION_PROPS, SzAction } from './action.model';

@NgModule({
  imports: [CommonModule]
})
export class SzActionAdminStoreModule {
  constructor(
    httpClient: HttpClient,
    entityDataService: EntityDataService,
    @Inject('ENV') private readonly environment
  ) {
    entityDataService.registerService(
      'SzAction',
      new GenericDataService<SzAction>(
        httpClient,
        ACTION_PROPS,
        this.environment.spaarze.urls.graphQl
      )
    );
  }
}
