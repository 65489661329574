import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Collection, fromJS, Map } from 'immutable';
import { isNullOrUndefined } from 'util';
import { AuthActions, AuthActionTypes } from './auth.actions';
import { JwtModel } from './auth.model';
import { JwtManager } from '../../jwtmanager/jwt-manager.service';

export const featureName = 'auth';
let jwtManager = new JwtManager();

export interface State {
  token?: string;
  isInitializing: boolean;
  isLoading: boolean;
  currentPurl: string;
  model:  Collection<string, any>;
}

export const initialState: State = {
  token: undefined,
  isInitializing: true,
  isLoading: false,
  currentPurl: '',
  model: Collection({})
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.GetToken:
      return {
        ...state,
        isInitializing: false,
        isLoading: true
      };
    case AuthActionTypes.GetTokenSuccess:
      return {
        ...state,
        isInitializing: false,
        isLoading: false,
        token: action.payload,
        model: fromJS(jwtManager.decryptJWTtoken(action.payload))
      };

    case AuthActionTypes.SetPurl:
      return {
        ...state,
        currentPurl: action.payload,
        isInitializing: false
      };

    case AuthActionTypes.Login:
      return {
        ...state,
        isInitializing: false,
        isLoading: true
      };

    case AuthActionTypes.Logout:
      return {
        ...state,
        isInitializing: false,
        isLoading: false,
        token: undefined
      };

    case AuthActionTypes.Register:
      return {
        ...state,
        isInitializing: false,
        isLoading: true
      };

    case AuthActionTypes.LoadAuthSuccess:
      if (!isNullOrUndefined(action.payload)) {
        return {
          ...state,
          isInitializing: false,
          isLoading: false,
          token: action.payload,
          model: fromJS(jwtManager.decryptJWTtoken(action.payload))
        };
      } else {
        return {
          ...state,
          isInitializing: true,
          isLoading: false,
          token: undefined
        };
      }

    case AuthActionTypes.GetTokenError:
      return {
        ...state,
        isInitializing: true,
        isLoading: false,
        token: undefined
      };

    case AuthActionTypes.RegisterError:
      return {
        ...state,
        isInitializing: true,
        isLoading: false
      };

    default:
      return state;
  }
}

const featureSelector = createFeatureSelector(featureName);

export const currentPurl = createSelector(
  featureSelector,
  (state: State) => state.currentPurl
);

export const authInitializing = createSelector(
  featureSelector,
  (state: State) => state.isInitializing
);
export const authLoading = createSelector(
  featureSelector,
  (state: State) => state.isLoading
);
export const authLoaded = createSelector(
  featureSelector,
  (state: State) => !state.isLoading && !state.isInitializing
);

export const authSelector = createSelector(
  featureSelector,
  (state: State) => {
    if (state.model !== undefined) {
      //token decrypt
      return state.model.toJS() as JwtModel;
    }

    return null;
  }
);

export const tokenValidationCheck = createSelector(
  featureSelector,
  (state: State) => {
    if (state.token !== undefined) {
      if (!jwtManager.checkExpiration(state.token)) {
        return true
      }
    }
    return false;
  }
);

export const isAuthenticatedForPurl = purl =>
  createSelector(
    featureSelector,
    (state: State) => {
      if (state.model !== undefined) {
        const auth = state.model.toJS() as JwtModel;
        if (auth.publicPurl === purl) {
          return true;
        }
      }
      return false;
    }
  );

export const isAuthenticated = createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.model)) {
      return true;
    }
    return false;
  }
);

export const tokenSelector = createSelector(
  authSelector,
  (auth: JwtModel) => {
    if (auth !== null) {
      return sessionStorage.getItem("authStorageKey");
    }
    return undefined;
  }
);

export const moduleAccessSelector = createSelector(
  authSelector,
  (auth: JwtModel) => {
    if (auth !== null) {
      return {modules: auth.modules, userType: auth.userType};
    }
    return undefined;
  }
);

export const userTypeSelector = createSelector(
  authSelector,
  (auth: JwtModel) => {
    if (auth !== null) {
      return auth.userType;
    }
    return undefined;
  }
);

export const isOwnerSelector = createSelector(
  authSelector,
  (auth: JwtModel) => {
    if (auth !== null) {
      return auth.isOwner + '' === 'true' || auth.isOwner + '' === 'True';
    }
    return undefined;
  }
);

export const authenticatedPurlSelector = createSelector(
  authSelector,
  (auth: JwtModel) => {
    if (auth !== null) {
      return auth.publicPurl;
    }

    return undefined;
  }
);
