<mat-card class="med-html-input">
  <mat-card-header>
    <mat-card-title>{{Label}}</mat-card-title>
  </mat-card-header>
  <ckeditor #editor editorUrl="/assets/ckeditor/ckeditor.js"
            [(ngModel)]="value" id="html" name="html">
  </ckeditor>
<!--    <angular-editor [placeholder]="Placeholder" [(ngModel)]="value" [required]="required" [disabled]="disabled" >-->
<!--    </angular-editor>-->
</mat-card>
