<mat-form-field [appearance]="appearance" [class]="CssClass" [class.hint]="Hint">
  <mat-label>{{ Label }}</mat-label>
  <input matInput
         [placeholder]="Placeholder"
         [(ngModel)]="value"
         [type]="type"
         [step]="step"
         [min]="min"
         [max]="max"
         [maxlength]="maxlength"
         [required]="required"
         [email]="email"
         [disabled]="disabled"
         [readonly]="readonly">
<!--  <mat-icon *ngIf="Hint" matSuffix>info</mat-icon>-->
  <mat-hint *ngIf="Hint">{{Hint}}</mat-hint>
  <mat-error *ngIf="Error">{{Error}}</mat-error>
</mat-form-field>
