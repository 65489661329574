import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'spaarze-layout-button',
  templateUrl: './spaarze-button.component.html',
  styleUrls: ['./spaarze-button.component.scss']
})
export class SpaarzeButtonComponent  {

  @Input() textColor = 'var(--primary-color)';
  @Input() backgroundColor = 'var(--primary-background-color)';
  @Output() readonly onClick: EventEmitter<any> = new EventEmitter();

  executeAction(): void {
    this.onClick.emit(undefined);
  }

}
