<div class="product-card" (click)="executeAction()">
  <div class="image" [style.background-image]="'url(' + getImage() + ')'"></div>
  <div class="content">
    <div class="product-title">{{ name }}</div>

    <div class="product-price">
      {{ priceCodes }} punten
      <ng-container *ngIf="price > 0">
        <span class="add">+</span>
        &euro;{{ price | number:'1.2-2' }}
      </ng-container>
    </div>
  </div>
</div>
