import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AdalInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (this.adal8Service.userInfo && this.adal8Service.userInfo.authenticated) {
    //   const newRequest = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${this.adal8Service.userInfo.token}`
    //     }
    //   });
    //   return next.handle(newRequest);
    // }
    return next.handle(request);
  }
}
