import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { SzAction } from './action.model';

@Injectable({ providedIn: 'root' })
export class SzActionService extends EntityCollectionServiceBase<SzAction> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SzAction', serviceElementsFactory);
  }
}
