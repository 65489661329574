<div class="bonus-products" *ngIf="bonusProducts?.length > 0">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-xl mt-3 mt-xl-0 product-list">
        <ng-container *ngFor="let bonusProduct of bonusProducts">
          <spaarze-layout-bonus-product-item
            [name]="bonusProduct.name"
            [extraCodeText]="bonusProduct.extraCodeText"
            [image]="bonusProduct.image"
            [toDate]="bonusProduct.toDate"
            [organisationId]="bonusProduct.supermarketId">

          </spaarze-layout-bonus-product-item>
        </ng-container>
      </div>
      <div class="col-lg-4 col-xl">
        <h2 class="sub-title">Spaarversnellers!</h2>
        <h3 class="title">Spaar nu nog sneller</h3>
      </div>
    </div>
  </div>
</div>
