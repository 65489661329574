import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MenuItem } from '../../shared/menu.model';

@Component({
  selector: 'spaarze-layout-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  @Input() height: string;
  @Input() menuItems: Array<MenuItem>;
  @Input() purl: string;
  @Output() readonly toggleFunction = new EventEmitter();

  constructor(private router: Router) {}

  navigate(purl: string, url: string): void {
    this.router.navigate((this.purl + url).split('/'));
    this.toggleFunction.emit();
  }

}
