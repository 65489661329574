import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Collection, fromJS, Map } from 'immutable';
import { isNullOrUndefined } from 'util';
import { ProspectSupermarketActions, ProspectSupermarketActionTypes } from './prospect-supermarket.actions';
import { ProspectSupermarket, ProspectSupermarketPurl } from './prospect-supermarket.model';

export const szProspectSupermarketFeatureName = 'SzProspectSupermarket';

interface State  {
  supermarket: Collection<string, any>;
  isInitializing: boolean;
  isLoading: boolean;
}

const initialState: State = {
  supermarket: Collection({}),
  isInitializing: true,
  isLoading: false
};

export function szProspectSupermarketReducer(state = initialState, action: ProspectSupermarketActions): State {
  switch (action.type) {
    case ProspectSupermarketActionTypes.LoadProspect:
      return {...state,
              isInitializing: false,
              isLoading: true
      };

    case ProspectSupermarketActionTypes.LoadProspectSuccess:
      return  {...state,  isLoading: false, supermarket: fromJS(action.payload)};

    default:
      return state;
  }
}

const featureSelector = createFeatureSelector(szProspectSupermarketFeatureName);

export const prospectSupermarketInitializing = createSelector(
  featureSelector,
  (state: State) => state.isInitializing
);
export const prospectSupermarketLoading = createSelector(
  featureSelector,
  (state: State) => state.isLoading
);

export const prospectSupermarketPurlSelector = createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.supermarket)) {
      return state.supermarket.toJS() as ProspectSupermarketPurl;
    }
    return undefined;
  });

export const prospectSupermarketPurlRedirectPageSelector = createSelector(
  prospectSupermarketPurlSelector,
  (state: ProspectSupermarketPurl) => {
    if (!isNullOrUndefined(state)) {
      return state.redirectPage;
    }
    return undefined;
  });

export const prospectSupermarketSelector = createSelector(
  prospectSupermarketPurlSelector,
  (state: ProspectSupermarketPurl) => {
    if (!isNullOrUndefined(state)) {
      return state.prospectSupermarket;
    }
    return undefined;
  });
