import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MedButtonComponent } from './med-button/med-button.component';
import { MedCheckboxComponent } from './med-checkbox/med-checkbox.component';
import { MedCustomimagesettingUploadComponent } from './med-customimagesetting-upload/med-customimagesetting-upload.component';
import { MedDatefieldComponent, MyDateAdapter } from './med-datefield/med-datefield.component';
import { MedDatetimefieldComponent } from './med-datetimefield/med-datetimefield.component';
import { MedDisplayfieldComponent } from './med-displayfield/med-displayfield.component';
import { MedHTMLInputComponent } from './med-html-input/med-html-input.component';
import { MedImageUploadComponent } from './med-image-upload/med-image-upload.component';
import { MedRadiobuttonComponent } from './med-radiobutton/med-radiobutton.component';
import { MedSelectfieldComponent } from './med-selectfield/med-selectfield.component';
import { MedTextAreaComponent } from './med-textarea/med-textarea.component';
import { MedTextfieldComponent } from './med-textfield/med-textfield.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    AngularEditorModule,
    MatListModule,
    MatMomentDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatTabsModule,
    MatSelectModule,
    MatPaginatorModule,
    MatSnackBarModule,
    CKEditorModule,
    NgxMaterialTimepickerModule,
    MatStepperModule
  ],
  declarations: [
    MedCheckboxComponent,
    MedTextAreaComponent,
    MedTextfieldComponent,
    MedDatefieldComponent,
    MedDatetimefieldComponent,
    MedSelectfieldComponent,
    MedButtonComponent,
    MedRadiobuttonComponent,
    MedImageUploadComponent,
    MedHTMLInputComponent,
    MedDisplayfieldComponent,
    MedCustomimagesettingUploadComponent
  ],
  exports: [
    FormsModule,

    MedTextfieldComponent,
    MedTextAreaComponent,
    MedDatefieldComponent,
    MedDatetimefieldComponent,
    MedSelectfieldComponent,
    MedButtonComponent,
    MedRadiobuttonComponent,
    MedCheckboxComponent,
    MedHTMLInputComponent,

    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatTabsModule,
    MedImageUploadComponent,
    MatSnackBarModule,
    MedDisplayfieldComponent,
    MedCustomimagesettingUploadComponent,
    MatSelectModule,
    MatOptionModule,
    MatExpansionModule,
    MatSortModule,
    MatRippleModule,
    MatTooltipModule
    ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MyDateAdapter
    }
  ]
})
export class MedFormsLibModule {}
