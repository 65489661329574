import { Component, Inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isAuthenticated } from '@x-fund-apps/med-authorization-lib';
import { MenuItem } from '../../shared/menu.model';
import { LogoutPromptComponent } from '../logout-prompt/logout-prompt.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'spaarze-layout-header-buttons',
  templateUrl: './header-buttons.component.html',
  styleUrls: ['./header-buttons.component.scss']
})
export class HeaderButtonsComponent {
  @Input() purl: string;
  @Input() shoppingcartUrl: string;
  @Input() shoppingcartCount: number;
  @Input() actionCodeCount: number;
  @Input() menuItems: Array<MenuItem>;
  @Input() isAuthenticated: boolean;

  showMenu = false;
  authenticated = this.store.select(isAuthenticated);

  constructor(
    public dialog: MatDialog,
    private store: Store<any>) {}

  showLogoutPopup(): void {
    this.dialog.open(LogoutPromptComponent, {data: { purl: this.purl }});
  }

  showChangePasswordPopup(): void {
    this.dialog.open(ChangePasswordComponent);
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }
}
