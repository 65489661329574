import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { EntityDataModule, EntityDataService } from '@ngrx/data';
import { GenericDataService } from '@x-fund-apps/med-shared';
import { BONUS_PRODUCT_PROPS, BonusProduct } from './bonus-product.model';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class BonusProductSupermarketStoreModule {
  constructor(
    httpClient: HttpClient,
    entityDataService: EntityDataService,
    @Inject('ENV') private readonly environment
  ) {
    entityDataService.registerService(
      'BonusProduct',
      new GenericDataService<BonusProduct>(
        httpClient,
        BONUS_PRODUCT_PROPS,
        this.environment.urls.supermarket.graph
      )
    );
  }
}
