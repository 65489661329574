import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, Logout, ValidateResult } from '@x-fund-apps/med-authorization-lib';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'spaarze-layout-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  changePasswordForm: FormGroup;
  errorMatcher = new CrossFieldErrorMatcher();

  isLoading = false;
  isSuccess = false;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
              private readonly authService: AuthService,
              private formBuilder: FormBuilder,
              private readonly store: Store<any>) {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: '',
      newPassword: '',
      verifyPassword: ''
    }, {
      validator: this.passwordValidator
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  passwordValidator(form: FormGroup): any {
    const condition = form.get('newPassword').value !== form.get('verifyPassword').value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  onLogoutClick(): void {
    this.isLoading = true;
    if (this.changePasswordForm.valid) {
      this.authService.ChangePassword(
                this.changePasswordForm.get('currentPassword').value,
                this.changePasswordForm.get('newPassword').value)
        .subscribe(
          response => {
            this.isLoading = false;
            this.isSuccess = true;
            setTimeout(() => this.close(), 900);
          },
          (err: HttpErrorResponse) => {
            this.isLoading = false;
            if (err.status === 401) {
              this.changePasswordForm.get('currentPassword').markAsTouched();
              this.changePasswordForm.get('currentPassword').setErrors({ passwordsNotCorrect: true });
            }
          }
        );
    }
  }
}
