import { Component } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Store } from '@ngrx/store';
import { supermarketBrandNameSelector } from '@x-fund-apps/spaarze/data';
import { ThemeService } from '@x-fund-apps/spaarze/layout';

@Component({
  selector: 'spaarze-website-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'spaarze-website';

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private store: Store<any>
  ) {
    angulartics2GoogleAnalytics.startTracking();

  }

}
