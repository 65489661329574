import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'spaarze-layout-bonus-product-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class BonusProductItemComponent {

  @Input() name: string;
  @Input() extraCodeText: string;
  @Input() image: string;
  @Input() organisationId: number;
  @Input() toDate: Date;

  imageUrl: string;

  constructor(@Inject('IMG') private imageService) {

  }

  getImage(): string {
    return this.imageService.getImage(`${this.organisationId}/bonusproducts`, this.image);
  }
}
