import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import {
  EntityActionOptions,
  EntityCollectionDataService,
  QueryParams
} from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { GraphQlResponse } from '@x-fund-apps/med-shared';
import { isUndefined } from 'util';

export class GenericDataService<T> implements EntityCollectionDataService<T> {
  name: string;
  private readonly graphApiUrl: string;
  private readonly pluralnamelower: string;
  private readonly singlenamelower: string;
  private readonly singlename: string;
  private readonly fields: string;

  getAllQuery(): string {
    return `{${this.pluralnamelower}{${this.fields}}}`;
  }

  createQuery(): string {
    return `mutation ($${this.singlenamelower}: ${this.singlename}Input!)
    {create${this.singlename}(${this.singlenamelower}: $${
      this.singlenamelower
    }){${this.fields}}}`;
  }

  alterQuery(): string {
    return `mutation ($${this.singlenamelower}: ${this.singlename}Input!)
    {alter${this.singlename}(${this.singlenamelower}: $${
      this.singlenamelower
    }){${this.fields}}}`;
  }

  deleteQuery(id): string {
    return `mutation
    {delete${this.singlename}(id: ${id})}`;
  }

  idQuery(id: number): string {
    return `{${this.pluralnamelower}(id: ${id} ) {${this.fields}}}`;
  }

  customQuery(params: string | QueryParams): string {
    return `{${this.pluralnamelower}(${params}) {${this.fields}}}`;
  }

  add(entity: T): Observable<T> {
    return this.httpClient
      .post(
        this.graphApiUrl,
        {
          query: this.createQuery(),
          variables: { [this.singlenamelower]: entity }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        map(value => (value as GraphQlResponse).data),
        map(value => value[`create${this.singlename}`])
      );
  }

  delete(id: string | number): Observable<string | number> {
    const success = 'success';
    return this.httpClient
      .post(
        this.graphApiUrl,
        {
          query: this.deleteQuery(id)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        map(value => (value as GraphQlResponse).data),
        map(value => value[success])
      );
  }

  getAll(): Observable<Array<T>> {
    return this.httpClient
      .post(
        this.graphApiUrl,
        { query: this.getAllQuery() },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        map(value => (value as GraphQlResponse).data),
        map(value => value[this.pluralnamelower])
      );
  }

  getById(id: any): Observable<T> {
    return this.httpClient
      .post(
        this.graphApiUrl,
        { query: this.idQuery(id) },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        map(value => (value as GraphQlResponse).data),
        map(value => value[this.pluralnamelower][0])
      );
  }

  getWithQuery(params: string | QueryParams): Observable<Array<T>> {
    const query =
      typeof params === 'object'
        ? `{${this.pluralnamelower}(${params.query}) {${params.fields}}}`
        : this.customQuery(params);

    return this.httpClient
      .post(
        this.graphApiUrl,
        { query },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        map(value => (value as GraphQlResponse).data),
        map(value => value[this.pluralnamelower])
      );
  }

  update(update: Update<T>): Observable<T> {
    return this.httpClient
      .post(
        this.graphApiUrl,
        {
          query: this.alterQuery(),
          variables: { [this.singlenamelower]: update.changes }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        map(value => (value as GraphQlResponse).data),
        map(value => value[`alter${this.singlename}`])
      );
  }

  upsert(entity: T): Observable<T> {
    throw new Error('Method not implemented.');
  }

  constructor(
    private readonly httpClient: HttpClient,
    props: GenericDataServiceProperties,
    graphUrl: string
  ) {
    this.graphApiUrl = graphUrl;
    this.fields = props.fields;
    this.singlename = props.singlename;
    this.singlenamelower = props.singlename.toLowerCase();
    this.pluralnamelower = props.pluralname.toLowerCase();
  }
}

export interface GenericDataServiceProperties {
  fields: string;
  singlename: string;
  pluralname: string;
}
