<div class="btn" *ngIf="shoppingcartUrl">
  <div
    class="shoppingcart-link mx-auto"
    [routerLink]="['/' + purl + '/' + shoppingcartUrl]"
  >
    <div class="badge" *ngIf="shoppingcartCount > 0">
      {{ shoppingcartCount }}
    </div>
  </div>
</div>
<div class="btn" *ngIf="isAuthenticated">
  <div class="profile-link mx-auto"  [matMenuTriggerFor]="menu" >
    <div class="badge" *ngIf="actionCodeCount > 0">{{ actionCodeCount }}</div>
  </div>
</div>
<mat-menu #menu="matMenu">
  <div class="menu-item" *ngIf="shoppingcartUrl">Spaarpot: {{ actionCodeCount }} punten</div>
  <button mat-menu-item (click)="showChangePasswordPopup()">
    <span>Wachtwoord wijzigen</span>
  </button>
  <button mat-menu-item (click)="showLogoutPopup()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Uitloggen</span>
  </button>
</mat-menu>

<div class="btn d-md-none" *ngIf="menuItems != undefined">
  <div class="menu-link" (click)="toggleMenu()">
    menu
  </div>
</div>

<spaarze-layout-menu
  [height]="showMenu ? '100%' : '0px'"
  [menuItems]="menuItems"
  [purl]="purl"
  (toggleFunction)="toggleMenu()"
></spaarze-layout-menu>
