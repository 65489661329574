import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { LoginModel, RegisterModel
} from './auth.model';

@Injectable()
export class AuthService {

  private authStorageKey = 'authStorageKey';

  constructor(private httpClient: HttpClient, @Inject('ENV') private environment) {
  }

  Login(credentials: LoginModel): Observable<string> {
    return this.httpClient.post(
      this.environment.urls.website.authenticate,
      credentials,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'text'
      }
      )
      .pipe(
        tap(this.SaveToSessionstorage)
      );
  }

  ForgotPassword(username: string): Observable<Object> {
    return this.httpClient.post(this.environment.urls.website.forgotPassword, {username}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  ChangePassword(oldPassword: string, newPassword: string): Observable<Object> {
    return this.httpClient.post(this.environment.urls.website.changePassword, {oldPassword, newPassword}, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  Register(register: RegisterModel): Observable<string> {
    return this.httpClient.post(this.environment.urls.website.register, register, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'text'
    })
      .pipe(
        tap(this.SaveToSessionstorage),
        map(value => (value as string))
      );
  }

  getToken(purl: string): Observable<string> {
    return this.httpClient.post(this.environment.urls.website.authenticate,
      {
        purl,
        projectInstanceId: this.environment.projectInstanceId
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'text'
      })
      .pipe(
        tap(this.SaveToSessionstorage),
        map(value => (value as string))
      );
  }

  LoadFromSessionStorage(): string {
    const storageItem = sessionStorage.getItem('authStorageKey');
    if (isNullOrUndefined(storageItem)) {
      return undefined;
    }
    return storageItem;
  }

  RemoveFromSessionstorage(): void {
    sessionStorage.removeItem('authStorageKey');
  }

  SaveToSessionstorage(auth: string): void {
    sessionStorage.setItem('authStorageKey', auth);
  }
}
