import { Action } from '@ngrx/store';
import { Member, OrderItem } from './order.model';

export enum OrderActionTypes {
  LoadMember = '[Order] Load Member',
  LoadMemberSuccess = '[Order] Load Member Success',
  LoadMemberError = '[Order] Load Member Error',
  LoadAllOrderItems = '[Order] Load All Order Items',
  LoadAllOrderItemsSuccess = '[Order] Load All Order Items Success',
  LoadAllOrderItemsError = '[Order] Load  All Order Items Error',
  LoadAllMembers = '[Order] Load All Members',
  LoadAllMembersSuccess = '[Order] Load All Members Success',
  LoadAllMembersError = '[Order] Load All Members Error',
  SearchMembers = '[Order] Search Members',
  SearchMembersSuccess = '[Order] Search Members Success',
  SearchMembersError = '[Order] Search Members Error',
  CollectOrderItem = '[Order] Collect Orderitem',
  CollectOrderItemSuccess = '[Order] Collect Orderitem Success',
  CollectOrderItemError = '[Order] Collect Orderitem Error'
}

export class LoadMember implements Action {
  readonly type = OrderActionTypes.LoadMember;
}

export class LoadMemberSuccess implements Action {
  readonly type = OrderActionTypes.LoadMemberSuccess;
  constructor(public payload: Member) {}
}

export class LoadMemberError implements Action {
  readonly type = OrderActionTypes.LoadMemberError;
}

export class LoadAllOrderItems implements Action {
  readonly type = OrderActionTypes.LoadAllOrderItems;
  constructor(public payload: number) {}
}

export class LoadAllOrderItemsSuccess implements Action {
  readonly type = OrderActionTypes.LoadAllOrderItemsSuccess;
  constructor(public orderItems: Array<OrderItem>) {}
}

export class LoadAllOrderItemsError implements Action {
  readonly type = OrderActionTypes.LoadAllOrderItemsError;
}

export class CollectOrderItem implements Action {
  readonly type = OrderActionTypes.CollectOrderItem;
  constructor(public orderItem: OrderItem) {}
}

export class CollectOrderItemSuccess implements Action {
  readonly type = OrderActionTypes.CollectOrderItemSuccess;
  constructor(public orderItem: OrderItem) {}
}

export class CollectOrderItemError implements Action {
  readonly type = OrderActionTypes.CollectOrderItemError;
}

export class LoadAllMembers implements Action {
  readonly type = OrderActionTypes.LoadAllMembers;
}

export class LoadAllMembersSuccess implements Action {
  readonly type = OrderActionTypes.LoadAllMembersSuccess;
  constructor(public payload: Array<Member>) {}
}

export class LoadAllMembersError implements Action {
  readonly type = OrderActionTypes.LoadAllMembersError;
}

export class SearchMembers implements Action {
  readonly type = OrderActionTypes.SearchMembers;
  constructor(public searchterm: string) {}

}

export class SearchMembersSuccess implements Action {
  readonly type = OrderActionTypes.SearchMembersSuccess;
  constructor(public payload: Array<Member>) {}
}

export class SearchMembersError implements Action {
  readonly type = OrderActionTypes.SearchMembersError;
}

export type OrderActions =
  | LoadMember
  | LoadMemberSuccess
  | LoadMemberError
  | LoadAllOrderItems
  | LoadAllOrderItemsSuccess
  | LoadAllOrderItemsError
  | CollectOrderItem
  | CollectOrderItemSuccess
  | CollectOrderItemError
  | LoadAllMembersSuccess
  | LoadAllMembers
  | LoadAllMembersError
  | SearchMembers
  | SearchMembersError
  | SearchMembersSuccess;
