import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Supermarket } from './supermarket.model';
import { GraphQlResponse, ThrowGraphQlError } from '@x-fund-apps/med-shared';
import { isNullOrUndefined } from 'util';

const fieldList = `id
name
street
houseNumber
zipCode
city
contactEmailAddress
contactPhone
purl
logo
homeImage
motherOrganisationId
hasEntrepreneurs
entrepreneursDescription
supermarketBrand {
  name
}
products {
  id
  name
  logo
  price
  priceCodes
  description
  productGroupId
  stockLeft
  supermarketId
  significant
  hasCheque
}
entrepreneurs {
  name
}
bonusProducts {
  image
  name
  description
  extraCodeText
  supermarketId
  toDate
}`;

const actionDataQuery = (purl: string) => `{
    supermarket(purl: "${purl}") {
      ${fieldList}
    }
  }`;

type Variables = undefined;

@Injectable()
export class SupermarketService {
  constructor(
    @Inject('ENV') private readonly environment,
    private httpClient: HttpClient,
    @Inject('IMG') private imageService
  ) {}

  getData(purl: string): Observable<Supermarket> {
    return this.httpClient
      .post(
        this.environment.urls.webshop.graph,
        { query: actionDataQuery(purl) },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        tap((value: GraphQlResponse) => {
          if (!isNullOrUndefined(value.errors)) {
            ThrowGraphQlError(this.environment.urls.webshop.graph, value.errors);
          }
        }),
        map(value => (value as GraphQlResponse).data),
        map(value => (value as GraphQlSupermarketResponse).supermarket)
      );
  }
}

export class GraphQlSupermarketResponse {
  supermarket: Supermarket;
}
