import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import {
  AuthActions,
  AuthActionTypes, ForgotPasswordError, ForgotPasswordSuccess,
  GetTokenError,
  GetTokenSuccess, LoadAuthSuccess, LogoutSuccess, RegisterError
} from './auth.actions';
import { AuthService } from './auth.service';
import { isNullOrUndefined } from 'util';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JwtManager } from '../../jwtmanager/jwt-manager.service';

@Injectable()
export class AuthEffects {

  @Effect() getToken$ = this.actions$.pipe(
    ofType(AuthActionTypes.GetToken),
    switchMap(action => this.authService.getToken(action.payload).pipe(
      switchMap((auth: string) => of(new GetTokenSuccess(auth))),
      catchError(err =>
        of(new GetTokenError()))
    ))
  );

  @Effect() loadAuth$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoadAuth),
    switchMap(action => of(this.authService.LoadFromSessionStorage())
      .pipe(
        switchMap((auth: string) => of(new LoadAuthSuccess(auth))),
        catchError(err =>
          of(new GetTokenError()))
      )
    )
  );

  @Effect() login$ = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    switchMap(action =>
      this.authService.Login(action.payload)
        .pipe(
          switchMap((auth: string) => of(new GetTokenSuccess(auth))),
          catchError(err =>
            of(new GetTokenError()))
        )
    )
  );

  @Effect() tokenError$ = this.actions$.pipe(
    ofType(AuthActionTypes.GetTokenError),
    tap(() =>
      this._matSnackBar.open(
        'Probleem met inloggen, controleer uw gebruikersnaam en wachtwoord en probeer het opnieuw',
        undefined,
        {
          duration: 2000
        }
      )
    ),
    filter(() => false)
  );

  @Effect() forgotPassword$ = this.actions$.pipe(
    ofType(AuthActionTypes.ForgotPassword),
    switchMap(action =>
      this.authService.ForgotPassword(action.payload)
        .pipe(
          switchMap(() => of(new ForgotPasswordSuccess())),
          catchError(err =>
            of(new ForgotPasswordError()))
        )
    )
  );

  @Effect() register$ = this.actions$.pipe(
    ofType(AuthActionTypes.Register),
    switchMap(action =>
      this.authService.Register(action.payload)
        .pipe(
          switchMap((auth: string) => of(new GetTokenSuccess(auth))),
          catchError(err =>
            of(new RegisterError()))
        )
    )
  );

  @Effect() registerError$ = this.actions$.pipe(
    ofType(AuthActionTypes.RegisterError),
    tap(() =>
      this._matSnackBar.open(
        'Probleem met registratie, e-mail al in gebruik',
        undefined,
        {
          duration: 2000
        }
      )
    ),
    filter(() => false)
  );

  @Effect({dispatch: false}) getTokenSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.GetTokenSuccess),
    tap(action => sessionStorage.setItem('authStorageKey', action.payload)),
    tap(action => {

      if (isNullOrUndefined(this.jwtManager.getPublicPurl(action.payload))) {
        this.router.navigate([this.jwtManager.getPurlType(action.payload)]);
      } else {
        this.router.navigate([this.jwtManager.getPublicPurl(action.payload), this.jwtManager.getPurlType(action.payload)]);
      }
    }),
    catchError(err =>
      of(new GetTokenError())),
    filter(() => false)
  );

  @Effect() logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    switchMap(action => of(this.authService.RemoveFromSessionstorage())
      .pipe(
        switchMap(() => of(new LogoutSuccess())),
        catchError(err =>
          of(new GetTokenError()))
      )
    )
  );

  private jwtManager = new JwtManager();

  constructor(
    private actions$: Actions<AuthActions>,
    private authService: AuthService,
    private router: Router,
    private readonly _matSnackBar: MatSnackBar) {}

}
