import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { authLoading, ForgotPassword, isValidEmail, SetPurl } from '@x-fund-apps/med-authorization-lib';
import {
  LoadSupermarket, supermarketBrandNameSelector,
  supermarketIdSelector,
  supermarketNameSelector,
  supermarketPurlSelector, supermarketSelector
} from '@x-fund-apps/spaarze/data';
import { isNullOrUndefined } from 'util';
import { menu } from '../shared/menu.model';
import { ImageService } from '../shared/image.service';
import { ThemeService } from '@x-fund-apps/spaarze/layout';

@Component({
  selector: 'spaarze-website-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  isLoading$ = this.store.select(authLoading);
  username: string;
  supermarketId$ = this.store.select(supermarketIdSelector);
  supermarketName$ = this.store.select(supermarketNameSelector);
  supermarket$ = this.store.select(supermarketSelector);

  purl$ = this.store.select(supermarketPurlSelector);
  menu = menu;
  message = '';

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    const purl = this.route.snapshot.params.purl;
    this.store.dispatch(new SetPurl(purl));
    this.store.dispatch(new LoadSupermarket(purl));

    this.store.select(supermarketBrandNameSelector).subscribe(brand => {
      this.themeService.SetThemeForBrand(brand);
    });
  }

  onSubmit(): void {
    if (!isNullOrUndefined(this.username) && isValidEmail(this.username)) {
      this.store.dispatch(
        new ForgotPassword(this.username)
      );

      this.message = 'Wij hebben je een e-mail gestuurd met een nieuw wachtwoord.';
    } else {
      this.message = 'Vul hieronder uw e-mailadres in en wij sturen u een nieuw wachtwoord.'
    }
  }
}
