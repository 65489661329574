import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'med-textarea',
  templateUrl: './med-textarea.component.html',
  styleUrls: ['./med-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedTextAreaComponent),
      multi: true
    }
  ]
})
export class MedTextAreaComponent implements ControlValueAccessor {
  get value() {
    return this.val;
  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  @Input() Label: string;
  @Input() Placeholder: string;
  @Input() CssClass = 'col';
  @Input() appearance = 'outline';
  @Input() required = false;
  @Input() disabled = false;
  @Input() fitContent = false;
  @Input() rows = 3;
  private val: object;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
