import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'med-textfield',
  templateUrl: './med-textfield.component.html',
  styleUrls: ['./med-textfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedTextfieldComponent),
      multi: true
    }
  ]
})
export class MedTextfieldComponent implements ControlValueAccessor {
  @Input() Label: string;
  @Input() Placeholder: string;
  @Input() Hint: string;
  @Input() Error: string;
  @Input() CssClass = 'col';
  @Input() type = 'text';
  @Input() step: string;
  @Input() min: number;
  @Input() max: number;
  @Input() maxlength: number;

  @Input() appearance: MatFormFieldAppearance = 'outline';

  @Input() required = false;
  @Input() email = false;
  @Input() disabled = false;
  @Input() readonly = false;

  private val: object;
  get value(): any {
    if (this.type === 'number') {
      return +this.val;
    }
    return this.val;
  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }
  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }
}
