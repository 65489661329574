import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { BonusProduct } from './bonus-product.model';
import { Observable } from 'rxjs';

@Injectable()
export class BonusProductService extends EntityCollectionServiceBase<BonusProduct> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('BonusProduct', serviceElementsFactory);
  }

  GetBySupermarketId(id: Number): Observable<Array<BonusProduct>> {
    this.clearCache();

    const query = `supermarketId: ${id}`;

    return this.getWithQuery(query);
  }
}
