<div class="menu" [style.height]="height">
  <div class="closebtn" (click)="toggleFunction.emit()"></div>

  <div class="menu-content">
    <a
      *ngFor="let item of menuItems"
      class="nav-link"
      (click)="navigate(purl, item.url)"
      routerLinkActive="active"
      >{{ item.name }}</a
    >
  </div>
</div>
