import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  supermarketAddressSelector,
  supermarketCitySelector,
  supermarketEmailSelector,
  supermarketNameSelector,
  supermarketPhoneSelector, supermarketZipCodeSelector
} from '@x-fund-apps/spaarze/data';

@Component({
  selector: 'spaarze-layout-footer-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent  {

  supermarketName$ = this.store.select(supermarketNameSelector);
  supermarketAddress$ = this.store.select(supermarketAddressSelector);
  supermarketCity$ = this.store.select(supermarketCitySelector);
  supermarketZipCode$ = this.store.select(supermarketZipCodeSelector);
  supermarketPhone$ = this.store.select(supermarketPhoneSelector);
  supermarketEmail$ = this.store.select(supermarketEmailSelector);

  constructor(
    public dialogRef: MatDialogRef<TermsAndConditionsComponent>,
    public store: Store<any>) {}

  close(): void {
    this.dialogRef.close();
  }

}
