export const menu = [
  {
    name: 'Home',
    url: '/login'
  },
  {
    name: 'Zo werkt het',
    url: '/howitworks'
  },
  {
    name: 'Registreer',
    url: '/register'
  }
];

export const menuHome = [
  {
    name: 'Home',
    url: '/home'
  },
  {
    name: 'Demo',
    url: '/demo'
  }
];
