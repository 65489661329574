import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthActionTypes, JwtManager, LoadAuthSuccess } from '@x-fund-apps/med-authorization-lib';
import { of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import {
  LoadSupermarket,
  LoadSupermarketError,
  LoadSupermarketSuccess,
  SupermarketActions,
  SupermarketActionTypes
} from './supermarket.actions';
import { Supermarket } from './supermarket.model';
import { SupermarketService } from './supermarket.service';

@Injectable()
export class SupermarketEffects {

  @Effect() afterAuth$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoadAuthSuccess),
    filter((action: LoadAuthSuccess) =>
      action.payload !== undefined
    ),
    switchMap((action: LoadAuthSuccess) =>
      of(new LoadSupermarket(this.jwtmanager.getPublicPurl(action.payload)))
    )
  );

  @Effect() loadSupermarket$ = this.actions$.pipe(
    ofType(SupermarketActionTypes.LoadSupermarket),
    switchMap((action: LoadSupermarket) =>
      this.supermarketService.getData(action.payload)
        .pipe(
          switchMap((supermarket: Supermarket) => of(new LoadSupermarketSuccess(supermarket))),
          catchError(() => of(new LoadSupermarketError()))
        )
    )
  );

  @Effect({dispatch: false}) loadSupermarketError$ = this.actions$.pipe(
    ofType(SupermarketActionTypes.LoadSupermarketError),
    tap(() => this.router.navigate(['/']))
  );

  private jwtmanager = new JwtManager();
  constructor(
    private actions$: Actions<SupermarketActions>,
    private supermarketService: SupermarketService,
    private  router: Router) {}
}
