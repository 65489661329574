import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'med-selectfield',
  templateUrl: './med-selectfield.component.html',
  styleUrls: ['./med-selectfield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedSelectfieldComponent),
      multi: true
    }]
})
export class MedSelectfieldComponent {
  get value() {
    return this.val;
  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  @Input() options: Array<{id: any, name: string}>;

  @Input() label: string;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() cssClass = 'col';
  @Input() type = 'text';
  @Input() nullText = 'none';
  @Input() showNullOption = true;

  @Input() required = false;
  @Input() email = false;
  @Input() disabled = false;

  private val: object;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

}
