import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'med-x-fund-apps-button',
  templateUrl: './med-button.component.html',
  styleUrls: ['./med-button.component.scss']
})
export class MedButtonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
