import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { ProspectSupermarketEffects } from './prospect-supermarket.effects';
import * as prospectsupermarket from './prospect-supermarket.reducer';
import { ProspectSupermarketService } from './prospect-supermarket.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(prospectsupermarket.szProspectSupermarketFeatureName, prospectsupermarket.szProspectSupermarketReducer),
    EffectsModule.forFeature([ProspectSupermarketEffects])
  ],
  providers: [ProspectSupermarketService]
})
export class SzProspectSupermarketDataModule {

}
