<mat-form-field appearance="outline" [class]="cssClass">
  <mat-label>{{ label }}</mat-label>
  <mat-select [(ngModel)]="value"
         [required]="required"
         [disabled]="disabled">
    <mat-option [value]="null" *ngIf="showNullOption">{{nullText}}</mat-option>
    <mat-option *ngFor="let option of options" [value]="option.id">{{option.name}}</mat-option>
  </mat-select>
  <!--  <mat-icon *ngIf="Hint" matSuffix>info</mat-icon>-->
  <mat-hint *ngIf="hint">{{hint}}</mat-hint>
</mat-form-field>
