import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from '@x-fund-apps/spaarze/layout';
import { menuHome } from '../shared/menu.model';

@Component({
  selector: 'spaarze-website-supermarket-demo',
  templateUrl: './supermarket-demo.component.html',
  styleUrls: ['./supermarket-demo.component.scss']
})
export class SupermarketDemoComponent implements  OnInit {
  purl: string;
  menu = [];

  constructor(private themeService: ThemeService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.themeService.SetThemeForBrand(undefined);
  }

  GoToPurl(): void {
    this.router.navigate(['demo', this.purl]);
  }
}
