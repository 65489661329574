<spaarze-layout-header
  [menuItems]="menu"
  [isAuthenticated]="false">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>
          {{ 'website.home.title' | translate }}
        </h1>
        <p>{{ 'website.home.text' | translate }}</p>

        <div class="row no-gutters purl">
          <div class="col-12">
            De link om uw eigen SpaarZe-shop aan te maken staat in flyer van de supermarkt. Door de link boven in de browser in te typen (dus niet in Google) gaat u naar de website. U kunt de link ook hier rechts invullen en op open klikken.
            <br />
            <br />
            <b>Ga naar SpaarZe van uw supermarkt:</b>
          </div>

          <div class="col-12 col-lg-5 label">
            www.spaarze.nl/
          </div>
          <div class="col-12 col-lg">
            <div class="input-group">
              <input
                type="text"
                name="purl"
                placeholder="bijv. supermarktcentraal"
                [(ngModel)]="purl"
                (keydown.enter)="GoToPurl()"
              />
              <button mat-ripple (click)="GoToPurl()" >Open</button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-none d-md-flex col-6 codes-image"
        [style.background-image]="
          'url(../../assets/images/pictures/Hand-home.jpg)'
        "
      ></div>
    </div>
  </div>
</spaarze-layout-header>
<div class="container">
  <div class="row">
    <div class="col mt-3">
      Wilt u als supermarkt een SpaarZe-actie starten of meer informatie? Mail dan naar info@spaarze.nl
    </div>
  </div>
</div>
