import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as authReducer from '../state/auth/auth.reducer';

@Injectable()
export class PurlInterceptor implements HttpInterceptor {
  token$ = this.store.select(authReducer.tokenSelector);
  token: string;

  constructor(private store: Store<any>) {
    this.token$.subscribe(token => (this.token = token));
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.token = sessionStorage.getItem("authStorageKey");
    if (this.token) {
      const newRequest = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.token
        }
      });
      return next.handle(newRequest);
    }
    return next.handle(request);
  }
}
