import {
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ImageUploadServiceInterface } from './ImageUploadServiceInterface';

@Component({
  selector: 'med-image-upload',
  templateUrl: './med-image-upload.component.html',
  styleUrls: ['./med-image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedImageUploadComponent),
      multi: true
    }]
})
export class MedImageUploadComponent implements ControlValueAccessor {
  get value(): string {
    return this.val;
  }
  set value(val: string) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
      this.loadImage(val);
    }
  }

  @Input() name: string;
  @Input() project: string;
  @Input() Label: string;
  @Input() Hint: string;
  @Input() CssClass = 'col';

  @Input() disabled = false;

  @Input() imageUploadService: ImageUploadServiceInterface;
  previewUrl: any = null;
  fileUploadProgress: string = null;

  private val: string;

  private fileData: File = null;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() { }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  fileSelected(fileInput: any): void {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
    this.submit();
  }

  preview(): void {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) === undefined) {
      this.previewUrl = '/assets/no-image.png';
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = _event => {
      this.previewUrl = reader.result;
    };
  }

  submit(): void {
    this.imageUploadService.postFile(
      this.project,
      this.name,
      this.fileData
    ).subscribe((events: HttpEvent<Object>) => {
      if (events.type === HttpEventType.UploadProgress) {
        this.fileUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
      } else if (events.type === HttpEventType.Response) {
        this.fileUploadProgress = '';
        this.value = (events.body as any).name;
      }
    });
  }

  remove(): void {
    this.value = null;
    this.fileData = undefined;
    this.previewUrl = null;
  }

  loadImage(val: string): void {
    if (!isNullOrUndefined(val)) {
      this.imageUploadService.getFile(this.project, val).subscribe((res: File) => {
        this.fileData = res;
        this.preview();
      });
    }
  }

  download(): void {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(this.fileData, this.val);
    } else {
      const anchor = window.document.createElement('a');
      anchor.href = window.URL.createObjectURL(this.fileData);
      anchor.download = this.val;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(anchor.href);
    }
  }

}
