import { GenericDataServiceProperties } from '@x-fund-apps/med-shared';

export class Entrepreneur {
  id: number;
  name: string;
  emailAddress: string;
  organisationId: number;
  userName: string;
  password: string;
  street: string;
  houseNumber: number;
  city: string;
  zipCode: string;
  kvkNumber: string;
  btwNumber: string;
  contactName: string;
  phone: string;
  iban: string;
  cheques: Array<{ id: number; code: number }>;
  credits: number;
}

export const ENTREPRENEUR_PROPS: GenericDataServiceProperties = {
  singlename: 'Entrepreneur',
  pluralname: 'Entrepreneurs',
  fields: `
    id
    name

    street
    houseNumber
    city
    zipCode
    country

    kvkNumber
    btwNumber
    iban

    contactName
    phone
    emailAddress
    credits
    `
};
