import { Action } from '@ngrx/store';
import { AuthModel, LoginModel, RegisterModel } from './auth.model';

export enum AuthActionTypes {
  SetPurl = '[Auth] Set purl',
  GetToken = '[Auth] Get Token',
  LoadAuth = '[Auth] Load',
  LoadAuthSuccess = '[Auth] Load Success',
  Login = '[Auth] Login',
  GetTokenSuccess = '[Auth] Get Token Success',
  GetTokenError = '[Auth] Get Token Error',
  Register = '[Auth] Register',
  RegisterError = '[Auth] Register Error',
  ForgotPassword = '[Auth] Forgot password',
  ForgotPasswordSuccess = '[Auth] Forgot password Success',
  ForgotPasswordError = '[Auth] Forgot password Error',
  Logout = '[Auth] Logout',
  LogoutSuccess = '[Auth] Logout Success'
}

export class SetPurl implements Action {
  readonly type = AuthActionTypes.SetPurl;
  constructor(public payload: string) {}
}

export class LoadAuth implements Action {
  readonly type = AuthActionTypes.LoadAuth;
}
export class LoadAuthSuccess implements Action {
  readonly type = AuthActionTypes.LoadAuthSuccess;
  constructor(public payload: string) {}
}

export class GetToken implements Action {
  readonly type = AuthActionTypes.GetToken;
  constructor(public payload: string) {}
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public payload: LoginModel) {}
}

export class GetTokenSuccess implements Action {
  readonly type = AuthActionTypes.GetTokenSuccess;
  constructor(public payload: string) {}
}

export class GetTokenError implements Action {
  readonly type = AuthActionTypes.GetTokenError;
}

export class Register implements Action {
  readonly type = AuthActionTypes.Register;
  constructor(public payload: RegisterModel) {}
}

export class RegisterError implements Action {
  readonly type = AuthActionTypes.RegisterError;
}

export class ForgotPassword implements Action {
  readonly type = AuthActionTypes.ForgotPassword;
  constructor(public payload: string) {}
}

export class ForgotPasswordSuccess implements Action {
  readonly type = AuthActionTypes.ForgotPasswordSuccess;
}

export class ForgotPasswordError implements Action {
  readonly type = AuthActionTypes.ForgotPasswordError;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutSuccess implements Action {
  readonly type = AuthActionTypes.LogoutSuccess;
}

export type AuthActions =
  SetPurl
  | GetToken
  | Login
  | LoadAuth
  | LoadAuthSuccess
  | GetTokenSuccess
  | GetTokenError
  | ForgotPassword
  | ForgotPasswordSuccess
  | ForgotPasswordError
  | Register
  | RegisterError
  | Logout
  | LogoutSuccess;
