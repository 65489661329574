import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'spaarze-layout-product-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ProductItemComponent {

  @Input() name: string;
  @Input() priceCodes: number;
  @Input() price: number;
  @Input() image: string;
  @Input() organisationId: number;
  @Output() readonly onClick: EventEmitter<any> = new EventEmitter();

  imageUrl: string;

  constructor(@Inject('IMG') private imageService) {

  }

  getImage(): string {
    return this.imageService.getImage(`${this.organisationId}/products`, this.image);
  }

  executeAction(): void {
    this.onClick.emit(undefined);
  }
}
