<spaarze-layout-loading-page *ngIf="(isLoading$ | async) || (isLoadingAuth$ | async)">
</spaarze-layout-loading-page>

<spaarze-layout-header [menuItems]="menu"
                       [supermarket]="supermarket$| async"
                       [isAuthenticated]="false">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>
          Welkom bij je<br />
          {{ supermarketName$ | async }} <br />SpaarZe-Shop
        </h1>
        <p>Log in om je codes in te voeren en te verzilveren.</p>

        <div class="row">
          <div class="col-12 col-md-9">
            <form name="form" #f="ngForm" novalidate class="center">
              <med-textfield
                name="username"
                [(ngModel)]="username"
                Label="Gebruikersnaam (meestal uw e-mailadres)"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="password"
                [(ngModel)]="password"
                type="password"
                Label="Wachtwoord"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <button
                class="col spaarze-login-button"
                mat-ripple
                (click)="onSubmit()"
              >
                Inloggen
              </button>
              <div>
                <span class="register link" [routerLink]="['..', 'register']"
                  >Nog geen account? Registreer&nbsp;hier</span
                >

                <span class="forgot link" [routerLink]="['..', 'forgot-password']"
                  >Wachtwoord vergeten?</span
                >
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="d-none d-md-flex col-6 codes-image"
        [style.background-image]=getHomeImageUrl()
      ></div>
    </div>
  </div>
</spaarze-layout-header>

<spaarze-layout-products
  [products]="products$ | async"
  [organisationId]="supermarketId$ | async"
  [showButtons]="false"
>
</spaarze-layout-products>

<div class="entrepreneur" *ngIf="supermarkt.hasEntrepreneurs">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-xl">
        <h2 class="sub-title">{{ supermarketName$ | async }} ondersteunt deze ondernemers: {{ supermarketEntrepreneursDescription$ | async }}</h2>
        Hoort u daar bij? Registreer u dan <span class="link" [routerLink]="['..', 'ondernemers']"
      >hier.</span>
      </div>
    </div>
  </div>
</div>
