import { JwtModel } from '@x-fund-apps/med-authorization-lib';

export class JwtManager {
  parseJwt(token): string {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  }

  decryptJWTtoken(token: string): JwtModel {
    const jwtData = token.split('.')[1]
    const decodedJwtJsonData = window.atob(jwtData)
    const decodedJwtData = JSON.parse(decodedJwtJsonData)


    const jwtmodel: JwtModel = {
      instanceId: decodedJwtData.InstanceId,
      purlType: decodedJwtData.PurlType,
      publicPurl: decodedJwtData.PublicPurl,
      isOwner: decodedJwtData.IsOwner,
      organisationId: decodedJwtData.OrganisationId,
      modules: decodedJwtData.Modules,
      userType: decodedJwtData.UserType
    }
    return jwtmodel;
  }

  // Check expiration date of JWT
  checkExpiration (exp) {
    const expDate = new Date(this.getRole(exp) * 1000)
    const currentDate = new Date()
    return expDate.getTime() <= currentDate.getTime();
  }

  // Get Role from JWT
  getRole(token: string) {
    const claims = this.parseJwt(token);
    return claims['exp'];
  }

  getPurlType(token: string): string {
    const claims = this.parseJwt(token);
    return claims['PurlType'];
  }
  getPublicPurl(token: string): string {
    const claims = this.parseJwt(token);
    return claims['PublicPurl'];
  }
}
