<mat-card >
  <mat-card-header>
    <mat-card-title>{{Label}}</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <img *ngIf="value && previewUrl" [src]="previewUrl" />
    <input type="file"
           [disabled]="disabled"
           (change)="fileSelected($event)">

    <input type="hidden"
           [(ngModel)]="value">
  </mat-card-content>

  <mat-card-actions>
    <button *ngIf="value" [disabled]="disabled" mat-button type="button" (click)="remove()">remove</button>
    <button *ngIf="value" [disabled]="disabled" mat-button type="button" (click)="download()">download</button>
  </mat-card-actions>
</mat-card>
