<mat-form-field [appearance]="appearance" [class]="CssClass">
  <mat-label>{{ Label }}</mat-label>
  <textarea matInput
         [placeholder]="Placeholder"
         [(ngModel)]="value"
         [required]="required"
         [rows]="rows"
         [cdkTextareaAutosize]='fitContent'
         [disabled]="disabled"></textarea>
</mat-form-field>
