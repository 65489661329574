import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LoginComponent } from './login/login.component';
import { RegisterEntrepreneurComponent } from './register/register-entrepreneur/register-entrepreneur.component';
import { RegisterComponent } from './register/register.component';
import { SupermarketDemoComponent } from './supermarket-demo/supermarket-demo.component';

const routes: Routes = [
  {
    path: 'demo',
    children: [
      {
        path: 'login',
        component: SupermarketDemoComponent
      },
      {
        path: ':purl',
        loadChildren: () =>
          import('@x-fund-apps/spaarze/supermarket-demo').then(
            m => m.SpaarzeSupermarketDemoModule
          )
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      }
    ]
  },
  {
    path: ':purl',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'register',
        component: RegisterComponent
      },
      {
        path: 'ondernemers',
        component: RegisterEntrepreneurComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'howitworks',
        component: HowItWorksComponent
      },
      {
        path: 'webshop',
        loadChildren: () =>
          import('@x-fund-apps/spaarze/webshop').then(
            m => m.SpaarzeWebshopModule
          )
      },
      {
        path: 'supermarket',
        loadChildren: () =>
          import('@x-fund-apps/spaarze/supermarket').then(
            m => m.SpaarzeSupermarketModule
          )
      },
      {
        path: 'entrepreneur',
        loadChildren: () =>
          import('@x-fund-apps/spaarze/entrepreneur').then(
            m => m.SpaarzeEntrepreneurModule
          )
      },
      {
        path: 'demo-supermarket',
        loadChildren: () =>
          import('@x-fund-apps/spaarze/supermarket-demo').then(
            m => m.SpaarzeSupermarketDemoModule
          )
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
