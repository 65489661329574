import { BonusProduct, Product } from '@x-fund-apps/spaarze/data';

export interface Supermarket {
  id: number;
  name: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  contactEmailAddress: string;
  contactPhone: string;
  logo: string;
  purl: string;
  supermarketBrand: SupermarketBrand;
  products: Array<Product>;
  bonusProducts: Array<BonusProduct>;
  motherOrganisationId: number;
  leafletEntrepreneur: string;
  homeImage: string;
  hasEntrepreneurs: boolean;
  entrepreneursDescription: string;
  entrepreneurs: Array<EntrepreneurSM>;
}

export interface SupermarketBrand {
  name: string;
}
export interface EntrepreneurSM {
  name: string;
}
