import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, NgModule } from '@angular/core';
import { EntityDataService } from '@ngrx/data';
import { GenericDataService } from '@x-fund-apps/med-shared';
import {
  SZ_PRODUCT_GROUP_PROPS,
  SzProductGroup
} from './sz-productgroup.model';

@NgModule({
  imports: [CommonModule]
})
export class SzProductGroupSupermarketStoreModule {
  constructor(
    httpClient: HttpClient,
    entityDataService: EntityDataService,
    @Inject('ENV') private readonly environment
  ) {
    entityDataService.registerService(
      'SzProductGroup',
      new GenericDataService<SzProductGroup>(
        httpClient,
        SZ_PRODUCT_GROUP_PROPS,
        this.environment.urls.supermarket.graph
      )
    );
  }
}
