import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from '@x-fund-apps/med-authorization-lib';
import { MedFormsLibModule } from '@x-fund-apps/med-forms-lib';
import { BonusProductComponent } from './bonus-product/bonus-product.component';
import { BonusProductItemComponent } from './bonus-product/item/item.component';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './footer/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './footer/terms-and-conditions/terms-and-conditions.component';
import { ChangePasswordComponent } from './header/change-password/change-password.component';
import { HeaderButtonsComponent } from './header/header-buttons/header-buttons.component';
import { HeaderComponent } from './header/header.component';
import { LogoutPromptComponent } from './header/logout-prompt/logout-prompt.component';
import { MenuComponent } from './header/menu/menu.component';
import { ProductItemComponent } from './products/item/item.component';
import { ProductsComponent } from './products/products.component';
import { SharedModule } from './shared/shared.module';
import { SpaarzeButtonComponent } from './spaarze-button/spaarze-button.component';
import { LoadingPageComponent } from './shared/loading-page/loading-page.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MedFormsLibModule,
    MatDialogModule,
    ReactiveFormsModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpaarzeButtonComponent,
    ProductsComponent,
    ProductItemComponent,
    LogoutPromptComponent,
    HeaderButtonsComponent,
    MenuComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    ChangePasswordComponent,
    BonusProductComponent,
    BonusProductItemComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingPageComponent,
    SpaarzeButtonComponent,
    ProductsComponent,
    BonusProductComponent,
    BonusProductItemComponent
  ],
  providers: [
    AuthService
  ],
  entryComponents: [
    LogoutPromptComponent,
    ChangePasswordComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent
  ]
})
export class SpaarzeLayoutModule {}
