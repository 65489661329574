import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import {
  LoadProspect,
  LoadProspectError,
  LoadProspectSuccess, ProspectSupermarketActions,
  ProspectSupermarketActionTypes
} from './prospect-supermarket.actions';
import { ProspectSupermarketPurl } from './prospect-supermarket.model';
import { ProspectSupermarketService } from './prospect-supermarket.service';

@Injectable()
export class ProspectSupermarketEffects {

  @Effect() loadProspectSupermarket$ = this.actions$.pipe(
    ofType(ProspectSupermarketActionTypes.LoadProspect),
    switchMap((action: LoadProspect) =>
      this.prospectSupermarketService.getData(action.payload)
        .pipe(
          switchMap((supermarket: ProspectSupermarketPurl) => of(new LoadProspectSuccess(supermarket))),
          catchError(() => of(new LoadProspectError()))
        )
    )
  );

  @Effect({dispatch: false}) loadSupermarketError$ = this.actions$.pipe(
    ofType(ProspectSupermarketActionTypes.LoadProspectError),
    tap(() => this.router.navigate(['/demo']))
  );

  constructor(
    private actions$: Actions<ProspectSupermarketActions>,
    private prospectSupermarketService: ProspectSupermarketService,
    private  router: Router) {}

}
