import {
  Component,
  EventEmitter,
  forwardRef, Injectable,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  parse(value: string) {
    const it = value.split('-');
    if (it.length === 3) {
      return new Date(+it[2], +it[1] - 1, +it[0], 12);
    }

  }

  format(date: Date, displayFormat: Object) {
    return ('0' + date.getDate()).slice(-2) + '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
  }
}

@Component({
  selector: 'med-datefield',
  templateUrl: './med-datefield.component.html',
  styleUrls: ['./med-datefield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedDatefieldComponent),
      multi: true
    }
  ]
})
export class MedDatefieldComponent implements ControlValueAccessor {

  get value(): any {
    return this.val;
  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  @Input() Label: string;
  @Input() Placeholder: string;
  @Input() Hint: string;
  @Input() CssClass = 'col';
  @Input() minDate: Date;

  @Input() required = false;
  @Input() email = false;
  @Input() disabled = false;
  @Input() readonly = false;
  private val: any;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  /*
    Temporary fix for saving previous day.
  */
  onKey(event): void {
    if (this.value instanceof Date) {
      this.value.setHours(12, 0);
    }
   // this.value =  moment(this.value, 'DD-MM-YYYY').toDate();
   // this.value.setHours(16, 0);
  }
}
