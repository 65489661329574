import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { SzProductGroup } from './sz-productgroup.model';
import { Observable } from 'rxjs';

@Injectable()
export class SzProductGroupService extends EntityCollectionServiceBase<SzProductGroup> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('SzProductGroup', serviceElementsFactory);
  }

  GetBySupermarketId(id: Number): Observable<Array<SzProductGroup>> {
    this.clearCache();

    const query = `supermarketId: ${id}`;

    return this.getWithQuery(query);
  }

}
