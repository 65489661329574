<div id="footer">
  <div class="footer-backgroud-hack"></div>
  <div class="container">
    <div class="row footer-content">
      <div class="col-sm-4 logo">
        <img src="/assets/images/Spaarze_logo.png">
      </div>
      <div class="col-sm-8 navigation">
        <div class="row">
          <div class="col justify-content-end">
            <a class="link" href="/assets/docs/AlgemeneVoorwaardenSpaarze.pdf" target="_blank">Algemene Voorwaarden</a>
            <span class="link" (click)="showPrivacyPopup()">Privacy Policy</span>
            <span class="link" *ngIf="(supermarketName$ | async)" (click)="showTermsPopup()">Actie voorwaarden</span>
          </div>
        </div>
        <div class="row">
          <div class="col justify-content-end copyright">
            SpaarZe is een initiatief van <a href="http://www.medialen.nl" target="_blank">Medialen&nbsp;BV</a>&nbsp;&copy;&nbsp;2020
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
