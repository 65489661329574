<div class="success">
  <div class="center">
    <div class="logo">
      Success
    </div>

    <!-- Material Design Spinner -->
    <div class="spinner-wrapper">
      <div class="spinner">
        <mat-icon>check-circle</mat-icon>
      </div>
    </div>
    <!-- / Material Design Spinner -->
  </div>
</div>
