<div class="header-container">
  <div class="header-backgroud-hack"></div>
  <div class="container">
    <div class="row">
      <div class="col-md-10 header">
        <!-- NAV -->
        <div class="row">
          <div class="col col-md-3 logo">
            <img *ngIf="supermarket"
                 [src]="getImage()"
                 [routerLink]="['/' + supermarket.purl + '/' + menuItems[0].url]" />

          </div>
          <div class="d-none d-md-block col-md-9 navigation">
            <ul class="nav justify-content-end ">
              <li class="nav-item" *ngFor="let item of menuItems">
                <a class="nav-link" *ngIf="supermarket"
                  [routerLink]="['/' + supermarket.purl + '/' + item.url]"
                  routerLinkActive="active">{{ item.name }}</a>
                <a class="nav-link" *ngIf="!supermarket"
                   [routerLink]="['/' + item.url]"
                   routerLinkActive="active">{{ item.name }}</a>
              </li>
            </ul>
          </div>
          <div class="d-block d-md-none col col-md-11 navigation">
            <spaarze-layout-header-buttons
              *ngIf="supermarket"
              class="d-flex justify-content-end"
              [purl]="supermarket.purl"
              [actionCodeCount]="actionCodeCount"
              [shoppingcartUrl]="shoppingcartUrl"
              [shoppingcartCount]="shoppingcartCount"
              [menuItems]="menuItems"
              [isAuthenticated]="isAuthenticated"
            ></spaarze-layout-header-buttons>
          </div>
        </div>
        <ng-content></ng-content>
      </div>

      <spaarze-layout-header-buttons
        *ngIf="supermarket"
        class="d-none d-md-flex"
        [purl]="supermarket.purl"
        [actionCodeCount]="actionCodeCount"
        [shoppingcartUrl]="shoppingcartUrl"
        [shoppingcartCount]="shoppingcartCount"
        [isAuthenticated]="isAuthenticated"
      ></spaarze-layout-header-buttons>
    </div>
  </div>
</div>
