import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  authLoading,
  isValid,
  Register,
  RegisterEntrepreneurModel,
  RegisterModel,
  SetPurl,
  ValidateResult
} from '@x-fund-apps/med-authorization-lib';
import {
  LoadSupermarket,
  Supermarket,
  supermarketBrandNameSelector, supermarketIdSelector, supermarketPurlSelector, supermarketSelector
} from '@x-fund-apps/spaarze/data';
import { PrivacyPolicyComponent, ThemeService } from '@x-fund-apps/spaarze/layout';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ImageService } from '../../shared/image.service';
import { menu } from '../../shared/menu.model';

@Component({
  selector: 'spaarze-website-register-entrepreneur',
  templateUrl: './register-entrepreneur.component.html',
  styleUrls: ['./register-entrepreneur.component.scss']
})
export class RegisterEntrepreneurComponent implements OnInit {
  registerModel: RegisterEntrepreneurModel = {
    organisationId: undefined,
    city: '',
    email: '',
    houseNumber: '',
    name: '',
    password: '',
    passwordRepeat: '',
    street: '',
    zipCode: '',
    userName: '',
    kvkNumber: '',
    btwNumber: '',
    isEntrepreneur: true,
    phone: '',
    iban: '',
    contactName: ''
  };

  isLoading$ = this.store.select(authLoading);
  supermarketId$ = this.store.select(supermarketIdSelector);
  supermarket$ = this.store.select(supermarketSelector);
  purl$ = this.store.select(supermarketPurlSelector);
  menu = menu;
  supermarkt: Supermarket;
  termsBool = false;

  constructor(
    private readonly store: Store<any>,
    private readonly route: ActivatedRoute,
    private imageService: ImageService,
    public dialog: MatDialog,
    private readonly _matSnackBar: MatSnackBar,
    private themeService: ThemeService

  ) {}

  ngOnInit(): void {
    const purl = this.route.snapshot.params.purl;
    this.supermarketId$.subscribe(
      id => (this.registerModel.organisationId = id)
    );

    this.supermarket$.subscribe(
      supermarkt => {
        (this.supermarkt = supermarkt);
      }
    );

    this.purl$.pipe(take(1)).subscribe(curPurl => {
      if (curPurl !== purl) {
        this.store.dispatch(new SetPurl(purl));
        this.store.dispatch(new LoadSupermarket(purl));
      }
    });

    this.store.select(supermarketBrandNameSelector).subscribe(brand => {
      this.themeService.SetThemeForBrand(brand);
    });
  }

  onSubmit(): void {
    if (!this.termsBool) {
      this._matSnackBar.open('U moet akkoord gaan met de algeme- en privacy voorwaarden', undefined, { duration: 3000 });
    } else {
      this.store.dispatch(new Register(this.registerModel));
    }  }

  isModelValid(registerModel: RegisterEntrepreneurModel): ValidateResult {
    return isValid(registerModel);
  }

  checkPasswords(group: FormGroup): any {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPass').value;

    return pass === confirmPass ? undefined : { notSame: true };
  }

  getHomeImageUrl(): string {
    if (!isNullOrUndefined(this.supermarkt.homeImage)) {
      return `url(${this.imageService.getImageProduct(
        this.supermarkt.id,
        this.supermarkt.homeImage
      )})`;
    }

    return 'url(../../assets/images/pictures/shutterstock_481976119.jpg)';
  }

  showTermsPopup(): void {
    this.dialog.open(PrivacyPolicyComponent);
  }
}
