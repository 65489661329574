<spaarze-layout-header [menuItems]="menu"
                       [supermarket]="supermarket$ | async"
                       [isAuthenticated]="false">
  <div class="content">
    <div class="row">
      <div class="col header-content">
        <h1>Zo werkt het</h1>
        <p>Het is super makkelijk:<br /></p>
        <ul>
          <li>
            Bij iedere 10 euro aan boodschappen ontvang je een
            Spaarze-spaarpunt bij {{ supermarketName$ | async }}.
          </li>
          <li>
            Je opent je SpaarZe-Shop, scant je codes, vult je spaarpot en
            bestelt de artikelen van je keuze.
          </li>
          <li>
            Je ontvangt van ons een afhaalbewijs en kunt de items in de winkel
            ophalen of indien het tegoedbonnen betreft ontvang je deze per mail.
          </li>
        </ul>
        <p>
          Dus maak nu snel je eigen SpaarZe-Shop aan.
        </p>
        <p>Voor vragen kun je een e-mail sturen naar: info@spaarze.nl</p>
      </div>
      <div
        class="d-none d-md-flex col-4 col-6 header-image"
        [style.background-image]=getHomeImageUrl()
      ></div>
    </div>
  </div>
</spaarze-layout-header>
