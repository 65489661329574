import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
  ProductInstance: {},
  ProductGroupInstance: {},
  ProductGroup: {},
  Product: {},
  MailType: {},
  MailTemplate: {},
  MailStartDate: {},
  MailSchedule: {},
  MailRecipient: {},
  MailMasterTemplate: {},
  MailCondition: {},
  Supermarket: {},
  SzSupermarket: {},
  SzAction: {},
  SzProduct: {},
  SzProductGroup: {},
  SzMember: {},
  TfProspectSchool: {},
  Order: {},
  OrderItem: {},
  ActionCode: {},
  SupermarketBrand: {},
  ProjectInstance: {},
  Project: {},
  Country: {},
  BonusProduct: {},
  Entrepreneur: {}
};

// because the plural of "hero" is not "heros"
const pluralNames = { };

export const entityConfig = {
  entityMetadata,
  pluralNames
};
