<h1 class="title">Wachtwoord wijzigen</h1>
<div class="close"
     (click)="close()"></div>

<form class="center" [formGroup]='changePasswordForm'>
  <mat-form-field class="col"
                  appearance="outline">
    <input type="password"
           matInput
           placeholder="Huidige wachtwoord"
           formControlName='currentPassword'
           autocomplete="current-password">
    <mat-error *ngIf="changePasswordForm.hasError('passwordsNotCorrect')">
      Huidige wachtwoord is niet geldig
    </mat-error>
  </mat-form-field>
  <mat-form-field  class="col"
                   appearance="outline">
    <input type="password"
           matInput
           placeholder="Nieuw wachtwoord"
           formControlName='newPassword'
           minlength="4"
           autocomplete="new-password">
  </mat-form-field>
  <mat-form-field class="col"
                  appearance="outline">
    <input type="password"
           matInput
           placeholder="Herhaal wachtwoord"
           formControlName='verifyPassword'
           [errorStateMatcher]="errorMatcher"
           minlength="4"
           autocomplete="new-password">
    <mat-error *ngIf="changePasswordForm.hasError('passwordsDoNotMatch')">
      Wachtwoorden komen niet overeen
    </mat-error>
  </mat-form-field>
</form>

<div class="popup-footer">
  <div class="alt-btn"
       (click)="close()">Annuleer</div>
  <div class="card-controls" >
    <div class="button"
         (click)="onLogoutClick()">Opslaan</div>
  </div>
</div>

<spaarze-layout-loading-page *ngIf="isLoading">
</spaarze-layout-loading-page>
<spaarze-layout-success-page *ngIf="isSuccess">
</spaarze-layout-success-page>
