import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'med-datetime',
  templateUrl: './med-datetimefield.component.html',
  styleUrls: ['./med-datetimefield.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedDatetimefieldComponent),
      multi: true
    }
  ]
})
export class MedDatetimefieldComponent implements ControlValueAccessor {
  get value(): string {
    return this.val;
  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
      this.displaytime = new Date(val).getHours() + ':' + new Date(val).getMinutes();
    }
  }
  @Input() DateLabel: string;
  @Input() TimeLabel: string;
  @Input() Placeholder: string;
  @Input() Hint: string;
  @Input() CssClass = 'col';

  @Input() required = false;
  displaydate: Date;
  displaytime: any;
  chosendate: Date;
  time: string;
  disabled: boolean;

  medTheme: NgxMaterialTimepickerTheme = {
    container: {
      buttonColor: '#039be5'
    },
    dial: {
      dialBackgroundColor: '#039be5'
    },
    clockFace: {
      clockHandColor: '#039be5'
    }
  };

  private val: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
    this.displaydate = value;
    this.chosendate = value;
  }
  timeselected(inputtime: string, inputdate: Date): void {
    const datepipe: DatePipe = new DatePipe('nl-NL');
    if (!isNullOrUndefined(inputtime)) {
      this.time = inputtime;
    } else if (isNullOrUndefined(this.time)) {
      this.time = this.displaytime;
    }
    if (!isNullOrUndefined(inputdate)) {
      this.chosendate = new Date (inputdate);
    }
    if (!isNullOrUndefined(this.chosendate) && !isNullOrUndefined(this.time)) {
      const date: Date = this.chosendate;
      const t1: any = this.time.split(' ');
      const t2: any = t1[0].split(':');
      const completeDate = new Date(date.setHours(t2[0], t2[1]));
      const fixedDate = datepipe.transform(completeDate, 'yyyy-MM-dd HH:mm:ss');
      this.value = fixedDate;
    }
  }
}
