import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spaarze-layout-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
