import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { Product } from './product.model';
import { AddToShoppingcart } from '../../../../webshop/src/lib/shoppingcart/state/shoppingcart.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'spaarze-layout-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {
  @Input() products: Array<Product>;
  @Input() organisationId: number;
  @Input() purl: string;
  @Input() showButtons = true;


  constructor(private router: Router,
              private store: Store<any>,
              @Inject('IMG') private imageService) {}

  navigateToProducts(): void {
    if (this.showButtons) {
      this.router.navigate([`${this.purl}/webshop/product`]);
    }
  }

  getImage(product: Product): string {
    return this.imageService.getImage(`${product.supermarketId}/products`, product.logo);
  }

  hasStock(product: Product): boolean {
    return isNullOrUndefined(product.stockLeft) || product.stockLeft > 0;
  }

  getSignificantProducts(): Array<Product> {
    if (isNullOrUndefined(this.products)) {
      return [];
    }
    return this.products.filter(item => this.hasStock(item) && item.significant);
  }

  addToCart(product: Product): void {

    this.store.dispatch(new AddToShoppingcart({ product, quantity: 1 }));
  }
}
