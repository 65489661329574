import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ImageService {
  private getFileUrl =  `${this.environment.apis.image}/getProductImage`;

  constructor(private httpClient: HttpClient, @Inject('ENV') private environment) {
  }

  getImage(project: number | string, name: string): string {
    if (isNullOrUndefined(project) || isNullOrUndefined(name)) {
      return '';
    }
    return `${this.getFileUrl}?name=${name}&project=${project}`;
  }

}
