import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spaarze-layout-success-page',
  templateUrl: './success-page.component.html',
  styleUrls: ['./success-page.component.scss']
})
export class SuccessPageComponent {

}
