import { Action } from '@ngrx/store';
import { ShoppingcartItem } from './shoppingcart.model';

export enum ShoppingcartActionTypes {
  AddToShoppingcart = '[Shoppingcart] Add To Shoppingcart',
  AddToShoppingcartDone = '[Shoppingcart] Add To Shoppingcart done',
  AddToShoppingcartWithText = '[Shoppingcart] Add To Shoppingcart With Text',
  AlterShoppingcart = '[Shoppingcart] Alter To Shoppingcart',
  ErrorShoppingcart = '[Shoppingcart] Error Shoppingcart',
  ClearShoppingcart = '[Shoppingcart] Clear Shoppingcart'
}

export class AddToShoppingcart implements Action {
  readonly type = ShoppingcartActionTypes.AddToShoppingcart;

  constructor(public payload: ShoppingcartItem) {}
}

export class AddToShoppingcartWithText implements Action {
  readonly type = ShoppingcartActionTypes.AddToShoppingcartWithText;

  constructor(public payload: ShoppingcartItem) {}
}

export class AddToShoppingcartDone implements Action {
  readonly type = ShoppingcartActionTypes.AddToShoppingcartDone;

}

export class AlterShoppingcart implements Action {
  readonly type = ShoppingcartActionTypes.AlterShoppingcart;

  constructor(public payload: ShoppingcartItem) {}
}

export class ClearShoppingcart implements Action {
  readonly type = ShoppingcartActionTypes.ClearShoppingcart;
}

export type ShoppingcartActions =
  | AddToShoppingcart
  | AddToShoppingcartDone
  | AddToShoppingcartWithText
  | AlterShoppingcart
  | ClearShoppingcart;
