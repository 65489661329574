import { deprecate } from 'util';

export class AuthModel {
  token: string;
  purlType: string;
  publicPurl: string;
  email?: string;
  isOwner: boolean;
  userType: string;
  /** @deprecated use organisationId instead */
  supermarketId?: number;
  organisationId: number;
  modules?: Array<string>;
}

export class JwtModel {
  purlType: string;
  publicPurl: string;
  isOwner: boolean;
  organisationId: number;
  modules?: Array<string>;
  instanceId: number;
  userType: string;
}

export class LoginModel {
  username: string;
  password: string;
  projectInstanceId: number;
}

export class RegisterModel {
  organisationId: number;
  name: string;
  email: string;
  password: string;
  passwordRepeat: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  isEntrepreneur: boolean;
}
export class RegisterEntrepreneurModel extends RegisterModel {
  userName: string;
  kvkNumber: string;
  btwNumber: string;
  phone: string;
  contactName: string;
  iban: string;
}

export function isValidEmail(email: string): boolean {
  const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
  if (email.length <= 5 || !EMAIL_REGEXP.test(email)) {
    return false;
  }
  return true;
}

export function isValid(registerModel: RegisterModel): ValidateResult {
  const result: ValidateResult = { valid: true, errors: '' };
  const ZIPCODE_REGEXP = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
  if (
    registerModel.name === '' ||
    registerModel.email === '' ||
    registerModel.password === '' ||
    registerModel.passwordRepeat === ''
  ) {
    result.valid = false;
    result.errors += 'Niet alle velden zijn ingevuld';
    return result;
  }
  if (registerModel instanceof RegisterEntrepreneurModel) {
    if (
      registerModel.kvkNumber === '' ||
      registerModel.btwNumber === '' ||
      registerModel.userName === ''
    ) {
      result.valid = false;
      result.errors += 'Niet alle velden zijn ingevuld';
      return result;
    }
    if (!ZIPCODE_REGEXP.test(registerModel.zipCode)) {
      result.valid = false;
      result.errors += 'Postcode ongeldig';
    }
  }

  if (!isValidEmail(registerModel.email)) {
    result.valid = false;
    if (result.errors !== '') {
      result.errors += ', ';
    }
    result.errors += 'Email ongeldig';
  }

  if (registerModel.password !== registerModel.passwordRepeat) {
    result.valid = false;
    if (result.errors !== '') {
      result.errors += ', ';
    }
    result.errors += 'Wachtwoorden zijn niet hetzelfde';
  }

  return result;
}

export interface ValidateResult {
  valid: boolean;
  errors: string;
}
