import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Collection, fromJS, Map } from 'immutable';
import { isNullOrUndefined } from 'util';
import { SupermarketActions, SupermarketActionTypes } from './supermarket.actions';
import { Supermarket, SupermarketBrand } from './supermarket.model';

export const SzSupermarketFeatureName = 'SzSupermarket';

interface State  {
  supermarket: Collection<string, any>;
  isInitializing: boolean;
  isLoading: boolean;
}

const initialState: State = {
  supermarket: Collection({}),
  isInitializing: true,
  isLoading: false
};

export function szSupermarketReducer(state = initialState, action: SupermarketActions): State {
  switch (action.type) {
    case SupermarketActionTypes.LoadSupermarket:
      return {...state,
              isInitializing: false,
              isLoading: true
      };

    case SupermarketActionTypes.LoadSupermarketSuccess:
      return  {...state,  isLoading: false, supermarket: fromJS(action.payload)};

    default:
      return state;
  }
}

const featureSelector = createFeatureSelector(SzSupermarketFeatureName);

export const supermarketInitializing = createSelector(
  featureSelector,
  (state: State) => state.isInitializing
);
export const supermarketLoading = createSelector(
  featureSelector,
  (state: State) => state.isLoading
);

export const supermarketSelector = createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.supermarket)) {
      return state.supermarket.toJS() as Supermarket;
    }

    return undefined;
  });

export const supermarketIdSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.id;
    }

    return undefined;
  });

export const supermarketNameSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.name;
    }

    return undefined;
  });

export const supermarketEntrepreneursDescriptionSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.entrepreneursDescription;
    }

    return undefined;
  });

export const supermarketAddressSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return `${supermarket.street} ${supermarket.houseNumber}`;
    }

    return undefined;
  });

export const supermarketZipCodeSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.zipCode;
    }

    return undefined;
  });

export const supermarketCitySelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.city;
    }

    return undefined;
  });

export const supermarketPhoneSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.contactPhone;
    }

    return undefined;
  });

export const supermarketEmailSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.contactEmailAddress;
    }

    return undefined;
  });

export const supermarketLogoSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.logo;
    }

    return undefined;
  });

export const supermarketHomeImageSelector = createSelector(
    supermarketSelector,
    (supermarket: Supermarket) => {
      if (!isNullOrUndefined(supermarket)) {
        return supermarket.homeImage;
      }

      return undefined;
    });

export const supermarketPurlSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.purl;
    }

    return undefined;
  });

export const supermarketProductsSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.products;
    }

    return undefined;
  });

export const supermarketBonusProductsSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) => {
    if (!isNullOrUndefined(supermarket)) {
      return supermarket.bonusProducts;
    }

    return undefined;
  });

export const supermarketBrandSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) =>
    supermarket.supermarketBrand);

export const supermarketBrandNameSelector = createSelector(
  supermarketBrandSelector,
  (brand: SupermarketBrand) => {
    if (!isNullOrUndefined(brand)) {
      return brand.name;
    }

    return undefined;
  });

export const supermarketEntrepreneursSelector = createSelector(
  supermarketSelector,
  (supermarket: Supermarket) =>
    supermarket.entrepreneurs);
