import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SetPurl } from '@x-fund-apps/med-authorization-lib';
import {
  LoadSupermarket,
  Supermarket,
  supermarketBrandNameSelector,
  supermarketNameSelector,
  supermarketPurlSelector,
  supermarketSelector
} from '@x-fund-apps/spaarze/data';
import { ThemeService } from '@x-fund-apps/spaarze/layout';
import { take } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { ImageService } from '../shared/image.service';
import { menu } from '../shared/menu.model';

@Component({
  selector: 'spaarze-website-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {
  purl$ = this.store.select(supermarketPurlSelector);
  menu = menu;
  supermarketName$ = this.store.select(supermarketNameSelector);
  supermarket$ = this.store.select(supermarketSelector);
  supermarkt: Supermarket;

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ImageService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    const purl = this.route.snapshot.params.purl;

    this.purl$.pipe(take(1)).subscribe(curPurl => {
      if (curPurl !== purl) {
        this.store.dispatch(new SetPurl(purl));
        this.store.dispatch(new LoadSupermarket(purl));
      }
    });

    this.supermarket$.subscribe(
      supermarkt => {
        (this.supermarkt = supermarkt);
      }
    );

    this.store.select(supermarketBrandNameSelector).subscribe(brand => {
      this.themeService.SetThemeForBrand(brand);
    });
  }

  getHomeImageUrl(): string {
    if (!isNullOrUndefined(this.supermarkt.homeImage)) {
          return `url(${this.imageService.getImageProduct(this.supermarkt.id, this.supermarkt.homeImage)})`;
    }

    return 'url(../../assets/images/pictures/s1200.jpg)';

  }
}
