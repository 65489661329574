import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GraphQlResponse, ThrowGraphQlError } from '@x-fund-apps/med-shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import {
  Member,
  MEMBER_FIELDS,
  Order,
  ORDER_FIELDS,
  OrderItem,
  ORDERITEM_FIELDS
} from './order.model';

const allMembersQuery = fields => `{members {${fields}}}`;
const searchMembersQuery = (fields, searchterm) =>
  `{members(searchterm: "${searchterm}") {${fields}}}`;

// const allOrdersQuery = fields => `{orders {${fields}}}`;
const allOrderItemsQuery = (fields, memberId) => `{orderitems(memberId: ${memberId}) {${fields}}}`;
// const searchOrdersQuery = (fields, searchterm) =>
//   `{orders(searchterm: "${searchterm}") {${fields}}}`;
const collectOrderItemQuery = (fields, id) =>
  `mutation {collectOrderItem(id: ${id}) {${fields}}}`;

@Injectable()
export class OrderService {
  private readonly graphApiUrl = this.environment.urls.supermarket.graph;

  constructor(
    private readonly httpClient: HttpClient,
    @Inject('ENV') private readonly environment
  ) {}

  getAll(): Observable<Array<Member>> {
    const query = allMembersQuery(MEMBER_FIELDS);

    return this.httpClient
      .post(
        this.graphApiUrl,
        { query },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        tap((value: GraphQlResponse) => {
          if (!isNullOrUndefined(value.errors)) {
            ThrowGraphQlError(query, value.errors);
          }
        }),
        map(value => value.data),
        map(value => (value as GraphQlMemberResponse).members)
      );
  }

  getAllOrderItems(memberId: number): Observable<Array<OrderItem>> {
    const query = allOrderItemsQuery(ORDERITEM_FIELDS, memberId);

    return this.httpClient
      .post(
        this.graphApiUrl,
        { query },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        tap((value: GraphQlResponse) => {
          if (!isNullOrUndefined(value.errors)) {
            ThrowGraphQlError(query, value.errors);
          }
        }),
        map(value => value.data),
        map(value => (value as GraphQlOrderItemResponse).orderitems)
      );
  }

  getFiltered(searchterm): Observable<Array<Member>> {
    const query = searchMembersQuery(MEMBER_FIELDS, searchterm);

    return this.httpClient
      .post(
        this.graphApiUrl,
        { query },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        tap((value: GraphQlResponse) => {
          if (!isNullOrUndefined(value.errors)) {
            ThrowGraphQlError(query, value.errors);
          }
        }),
        map(value => value.data),
        map(value => (value as GraphQlMemberResponse).members)
      );
  }

  collectOrderItem(orderitem: OrderItem): Observable<OrderItem> {
    const query = collectOrderItemQuery(ORDERITEM_FIELDS, orderitem.id);

    return this.httpClient
      .post(
        this.graphApiUrl,
        { query },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .pipe(
        tap((value: GraphQlResponse) => {
          if (!isNullOrUndefined(value.errors)) {
            ThrowGraphQlError(query, value.errors);
          }
        }),
        map(value => value.data),
        map(
          value => (value as GraphQlCollectOrderItemResponse).collectOrderItem
        )
      );
  }
}

export class GraphQlMemberResponse {
  members: Array<Member>;
}

export class GraphQlOrderItemResponse {
  orderitems: Array<OrderItem>;
}

export class GraphQlCollectOrderItemResponse {
  collectOrderItem: OrderItem;
}
