import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import {
  authLoading,
  Login,
  SetPurl
} from '@x-fund-apps/med-authorization-lib';
import {
  LoadSupermarket,
  Supermarket,
  supermarketBrandNameSelector,
  supermarketEntrepreneursDescriptionSelector,
  supermarketHomeImageSelector,
  supermarketIdSelector,
  supermarketLoading,
  supermarketNameSelector,
  supermarketProductsSelector,
  supermarketPurlSelector,
  supermarketSelector
} from '@x-fund-apps/spaarze/data';
import { ThemeService } from '@x-fund-apps/spaarze/layout';
import { isNullOrUndefined } from 'util';
import { ImageService } from '../shared/image.service';
import { menu } from '../shared/menu.model';

@Component({
  selector: 'spaarze-website-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isLoading$ = this.store.select(supermarketLoading);
  isLoadingAuth$ = this.store.select(authLoading);
  username: string;
  password: string;
  products$ = this.store.select(supermarketProductsSelector);
  supermarketId$ = this.store.select(supermarketIdSelector);
  supermarketName$ = this.store.select(supermarketNameSelector);
  supermarketEntrepreneursDescription$ = this.store.select(
    supermarketEntrepreneursDescriptionSelector
  );
  supermarket$ = this.store.select(supermarketSelector);
  supermarketHomeImage$ = this.store.select(supermarketHomeImageSelector);

  purl$ = this.store.select(supermarketPurlSelector);
  menu = menu;
  supermarkt: Supermarket;

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private router: Router,
    private imageService: ImageService,
    private themeService: ThemeService,
    @Inject('ENV') private readonly environment
  ) {}

  ngOnInit(): void {
    const purl = this.route.snapshot.params.purl;
    this.store.dispatch(new SetPurl(purl));
    this.store.dispatch(new LoadSupermarket(purl));

    this.supermarket$.subscribe(supermarkt => {
      this.supermarkt = supermarkt;
    });

    this.store.select(supermarketBrandNameSelector).subscribe(brand => {
      this.themeService.SetThemeForBrand(brand);
    });
  }

  onSubmit(): void {
    this.store.dispatch(
      new Login({
        username: this.username,
        password: this.password,
        projectInstanceId: this.environment.projectInstanceId
      })
    );
  }

  getHomeImageUrl(): string {
    if (!isNullOrUndefined(this.supermarkt.homeImage)) {
      return `url(${this.imageService.getImageProduct(
        this.supermarkt.id,
        this.supermarkt.homeImage
      )})`;
    }

    return 'url(../../assets/images/pictures/shutterstock_481976119.jpg)';
  }
}
