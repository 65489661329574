import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ImageService {
  private getFileUrl = `${this.environment.apis.image}/getFile`;
  private getPersonalImageUrl = `${
    this.environment.apis.image
  }/getPersonalImage`;
  private getProductUrl =  `${this.environment.apis.image}/getProductImage`;


  constructor(
    private httpClient: HttpClient,
    @Inject('ENV') private environment
  ) {}

  getImage(project: number | string, name: string): string {
    if (isNullOrUndefined(project) || isNullOrUndefined(name)) {
      return '';
    }
    return `${this.getFileUrl}?name=${name}&project=${project}`;
  }

  getImageProduct(project: number | string, name: string): string {
    if (isNullOrUndefined(project)) {
      return '';
    }
    return `${this.getProductUrl}?name=${name}&project=${project}`;
  }

  getPersonalImage(project: number | string, name: string): Observable<File> {
    if (isNullOrUndefined(project) || isNullOrUndefined(name)) {
      return of(undefined);
    }
    return this.httpClient
      .post(
        this.getPersonalImageUrl,
        { name, project },
        {
          responseType: 'blob'
        }
      )
      .pipe(map(blob => this.blobToFile(blob, name)));
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    const b: any = theBlob;
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    // Cast to a File() type
    return b as File;
  };
}
