import { GenericDataServiceProperties } from '@x-fund-apps/med-shared';

export class SzProductGroup {
  id: number;
  name: string;
  supermarketId: number;
}

export const SZ_PRODUCT_GROUP_PROPS: GenericDataServiceProperties = {
  singlename: 'SzProductGroup',
  pluralname: 'SzProductGroups',
  fields: `
    id
    name
    supermarketId
    `
};
