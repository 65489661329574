import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { ImageService } from './image.service';
import { SuccessPageComponent } from './success-page/success-page.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    SuccessPageComponent,
    LoadingPageComponent
  ],
  imports: [
    CommonModule,

    MatCardModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MomentModule
  ],
  exports: [
    MatCardModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MomentModule,

    SuccessPageComponent,
    LoadingPageComponent
  ],
  providers: [
    {
      provide: 'IMG',
      useClass: ImageService
    }
  ]
})
export class SharedModule {}
