import { Action } from '@ngrx/store';
import { Supermarket } from './supermarket.model';

export enum SupermarketActionTypes {
  LoadSupermarket = '[Supermarket] Load Supermarket',
  LoadSupermarketSuccess = '[Supermarket] Load Supermarket Success',
  LoadSupermarketError = '[Supermarket] Load Supermarket Error'
}

export class LoadSupermarket implements Action {
  readonly type = SupermarketActionTypes.LoadSupermarket;
  constructor(public payload: string) {}
}

export class LoadSupermarketSuccess implements Action {
  readonly type = SupermarketActionTypes.LoadSupermarketSuccess;
  constructor(public payload: Supermarket) {}
}

export class LoadSupermarketError implements Action {
  readonly type = SupermarketActionTypes.LoadSupermarketError;
}

export type SupermarketActions =
  | LoadSupermarket
  | LoadSupermarketSuccess
  | LoadSupermarketError;
