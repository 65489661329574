import { Injectable } from '@angular/core';
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory
} from '@ngrx/data';
import { Observable } from 'rxjs';
import { Entrepreneur } from './entrepreneur.model';

@Injectable({ providedIn: 'root' })
export class EntrepreneurService extends EntityCollectionServiceBase<Entrepreneur> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Entrepreneur', serviceElementsFactory);
  }

  getBySupermarketId(id: number): Observable<Array<Entrepreneur>> {
    this.clearCache();
    const query = `supermarketId: ${id}`;

    return this.getWithQuery(query);
  }
  search(term: string): Observable<Array<Entrepreneur>> {
    this.clearCache();
    const query = `searchterm: "${term}"`;

    return this.getWithQuery(query);
  }
}
