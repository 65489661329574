import { Component, Input } from '@angular/core';

@Component({
  selector: 'med-displayfield',
  templateUrl: './med-displayfield.component.html',
  styleUrls: ['./med-displayfield.component.scss']
})
export class MedDisplayfieldComponent {

  @Input() label: string;
  @Input() value: string;
  @Input() infoText: string;

}
