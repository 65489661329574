import { Component, Inject, Input } from '@angular/core';
import { Supermarket } from '@x-fund-apps/spaarze/data';
import { MenuItem } from '../shared/menu.model';

@Component({
  selector: 'spaarze-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() menuItems: Array<MenuItem>;
  @Input() shoppingcartUrl: string;
  @Input() shoppingcartCount: number;
  @Input() actionCodeCount: number;
  @Input() isAuthenticated = true;
  @Input() supermarket: Supermarket;

  constructor(@Inject('IMG') private imageService) { }

  getImage(): string {
    return this.imageService.getImage(`${this.supermarket.id}`, this.supermarket.logo);
  }
}
