
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'med-checkbox',
  templateUrl: './med-checkbox.component.html',
  styleUrls: ['./med-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedCheckboxComponent),
      multi: true
    }]
})
export class MedCheckboxComponent implements ControlValueAccessor {
  get value(): object {
    return this.val;
  }
  set value(val) {
    if (val !== undefined && this.val !== val) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
      this.changed.emit(val);
    }
  }

  @Output() readonly changed: EventEmitter<any> = new EventEmitter<any>();
  @Output() readonly linkClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() color = 'primary';
  @Input() Label: string;
  @Input() LabelLink: string;
  @Input() CssClass = 'col';
  @Input() appearance = 'outline';
  @Input() required = false;
  @Input() disabled = false;
  private val: object;

  onChange: any = () => {};
  onTouch: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  link(): void {
    this.linkClicked.emit();
  }

}
