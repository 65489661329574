
<div class="products" *ngIf="getSignificantProducts().length > 1">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-xl">
        <h2 class="sub-title">Shopknallers!</h2>
        <h3 class="title">Bekijk alle SpaarZe-Acties</h3>
        <spaarze-layout-button *ngIf="showButtons" (onClick)="navigateToProducts()">
          Naar SpaarZe-Acties
        </spaarze-layout-button>
      </div>
      <div class="col-lg-8 col-xl mt-3 mt-xl-0 product-list">
        <ng-container *ngFor="let product of getSignificantProducts()">
          <spaarze-layout-product-item
            [name]="product.name"
            [image]="product.logo"
            [organisationId]="product.supermarketId"
            [priceCodes]="product.priceCodes"
            [price]="product.price"
            (onClick)="navigateToProducts()"></spaarze-layout-product-item>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="product mt-3" *ngIf="getSignificantProducts().length === 1">
  <div class="container" *ngFor="let product of getSignificantProducts()">
    <div class="row">
      <div class="col-lg-4 ">
        <div class="image" [style.background-image]="'url(' + getImage(product) + ')'"></div>
      </div>
      <div class="col-lg-8 ">
        <h3>{{ product.name }}</h3>
        <div [innerHTML]="product.description"></div>

        <div class="product-price">
          {{ product.priceCodes }} punten
          <ng-container *ngIf="product.price > 0">
            <span class="add">+</span>
            &euro;{{ product.price | number:'1.2-2' }}
          </ng-container>
        </div>

        <div class="mt-4" *ngIf="showButtons">
          <spaarze-layout-button (onClick)="addToCart(product)">
            In winkelwagen
          </spaarze-layout-button>
        </div>
      </div>
    </div>
  </div>
</div>
