import { SupermarketBrand } from '@x-fund-apps/spaarze/data';

export interface ProspectSupermarket {
  id: number;
  name: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  brand: SupermarketBrand;
}

export interface ProspectSupermarketPurl {
  purl: string;
  redirectPage: string;
  prospectSupermarket: ProspectSupermarket;
}
