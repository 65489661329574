<h1 mat-dialog-title>Actie voorwaarden</h1>
<div class="close" (click)="close()"></div>

<div class="content">
  <p>
    Spaar jij mee voor de SpaarZe-acties? Je krijgt tot nader bericht bij iedere 10 euro een SpaarZe-spaarpunt. Deze punten kun je toevoegen in je SpaarZe-spaarpot en verzilveren in je SpaarZe-shop.
  </p>
  <p>
    Deze spaaractie wordt georganiseerd door:<br />
    {{supermarketName$ | async}} <br />
    {{supermarketAddress$ | async}} <br />
    {{supermarketZipCode$ | async}} {{supermarketCity$ | async}}
  </p>
  <ul>
    <li>Bij elke € 10,- aan boodschappen en bij speciale actieproducten ontvang je bij {{supermarketName$ | async}} {{supermarketAddress$ | async}} tot nader bericht gratis één Spaarzepunt.</li>
    <li>Deze SpaarZe-punten scan je in je persoonlijke SpaarZe-shop waardoor ze worden toegevoegd aan je spaarsaldo.</li>
    <li>Bij verzilvering van punten (+ eventuele bijbetaling) ontvang je een afhaalbewijs voor het ophalen van de gekozen SpaarZe-actie. Betreft het een tegoedbon dan ontvang je deze per mail. Eventuele bijbetaling varieert per actie en staat vermeld op het moment van bestellen.</li>
    <li>De SpaarZe-acties zijn enkel dan en uitsluitend af te halen zoals vermeld staat op het afhaalbewijs, tegoedbonnen ontvang je per mail.</li>
    <li>SpaarZe-acties kunnen enkel bij {{supermarketName$ | async}} {{supermarketAddress$ | async}} worden verzilverd.</li>
    <li>Je ontvangt enkel SpaarZe-{{supermarketName$ | async}}-punten bij {{supermarketName$ | async}} {{supermarketAddress$ | async}}.</li>
    <li>Over de aanschaf van de volgende producten en diensten kunnen geen SpaarZe-punten worden gespaard: tabak (rookwaren), slijterijartikelen, geneesmiddelen, zuigelingenvoeding tot 1 jaar, beltegoedkaarten, vervoersbewijzen, waardebonnen, cadeaukaarten, loten, postzegels, boeken, toegangskaarten, emballage en statiegeld.</li>
    <li>De SpaarZe-acties zijn alleen verkrijgbaar bij verzilvering van SpaarZe-punten en de bijbehorende bijbetaling in de SpaarZe-shop. De items zijn niet los te koop.</li>
    <li>De SpaarZe-acties zijn verkrijgbaar zolang de voorraad strekt. Zowel {{supermarketName$ | async}} {{supermarketAddress$ | async}} als SpaarZe zijn niet aansprakelijk voor ‘uitverkochte’ SpaarZe-acties.</li>
    <li>Als een SpaarZe-actie onverhoopt is ‘uitverkocht’ zullen de verzilverde punten weer worden toegevoegd aan uw SpaarZe-saldo.</li>
    <li>{{supermarketName$ | async}} behoudt zich het recht voor afhaalbewijzen te weigeren die niet aan de spaarvoorwaarden voldoen of die frauduleus overkomen.</li>
    <li>SpaarZe-zegels zijn niet inwisselbaar voor contant geld of een andere waardecompensatie maar uitsluitend tegen de dan lopende SpaarZe-acties.</li>
    <li>SpaarZe-zegels kunnen slechts eenmaal worden gebruikt.</li>
    <li>Deelname aan de {{supermarketName$ | async}} SpaarZe-actie impliceert de onvoorwaardelijke aanvaarding van deze actievoorwaarden, zonder enig voorbehoud of beperking. Bij overtreding van één van de in deze actievoorwaarden vermelde bepalingen is de verdere deelname aan deze actie uitgesloten.</li>
    <li>Druk- en zetfouten voorbehouden.</li>
    <li>Op deze voorwaarden is Nederlands recht van toepassing.</li>
    <li>{{supermarketName$ | async}} en SpaarZe zijn niet aansprakelijk voor schade die voortvloeit uit het gebruik van de SpaarZe-acties. Het gebruik hiervan is dan ook voor eigen risico.</li>
    <li>{{supermarketName$ | async}} behoudt zich het recht voor de spaarvoorwaarden tussentijds te wijzigen en/of de actie voortijdig te beëindigen.</li>
  </ul>
  <p>
    Als je vragen of opmerkingen hebt over de spaaractie, neem dan contact met ons op:<br />
    {{supermarketName$ | async}}, {{supermarketAddress$ | async}} of SpaarZe: tel. 0499-392293
  </p>
</div>
