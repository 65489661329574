export class Member {
  id: number;
  name: string;
  emailAddress: string;
  barcode: string;

  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  country: string;

  credits: number;

  orders: Array<Order>;
}

export class Order {
  id: number;
  name: string;
  emailAddress: string;

  street: string;
  houseNumber: string;
  city: string;
  zipCode: string;
  country: string;

  remarks: string;
  isPayed: boolean;

  refOrderId: string;
  created: Date;
  transactionCost: string;
  totalPrice: string;
  totalAmount: string;

  totalPriceCodes: string;
  collected: boolean;
  ogonePm: string;
  ogoneAcceptance: string;
  ogoneStatus: string;
  ogonePayId: string;
  organisationId: number;

  orderItems: Array<OrderItem>;
}

export class OrderItem {
  id: number;
  orderId?: number;
  productId?: number;
  product: Product;
  amount?: number;
  pickUpDate?: Date;
  text: string;
}

export class Product {
  id: number;
  name: string;
  logo: string;
  collectStartDate: Date;
  collectEndDate: Date;
}

export const ORDERITEM_FIELDS = `
id
productId
product {
  id
  name
  logo
  collectStartDate
  collectEndDate
}
orderId
amount
pickUpDate
text`;

export const ORDER_FIELDS = `
  id
  name
  emailAddress
  street
  houseNumber
  city
  zipCode
  country
  remarks
  isPayed
  created
  refOrderId
  transactionCost
  totalPriceCodes
  totalPrice
  totalAmount
  collected
  orderItems{
    id
  }
  `;

export const MEMBER_FIELDS = `
  id
  name
  emailAddress
  street
  houseNumber
  city
  zipCode
  country
  credits
  orders {
    ${ORDER_FIELDS}
  }
`;
