<mat-form-field appearance="outline" [class]="CssClass">
  <mat-label>{{ Label }}</mat-label>
  <input matInput
         [matDatepicker]="picker"
         [placeholder]="Placeholder"
         [(ngModel)]="value"
         [required]="required"
         [disabled]="disabled"
         [readonly]="readonly"
         [min]="minDate"
         format="dd/mm/yyyy"
         (ngModelChange)="onKey($event)">
  <mat-datepicker-toggle matSuffix [for]="picker" [disabled]=" disabled || readonly"></mat-datepicker-toggle>
  <mat-datepicker #picker [(ngModel)]="value" ngDefaultControl ></mat-datepicker>
  <mat-hint *ngIf="Hint">{{Hint}}</mat-hint>
</mat-form-field>
