<div class="container">
<div class="row">
  <div class="col-6"
  >
<med-datefield 
    placeholder=dd-mm-y
    Label={{DateLabel}}
    [(ngModel)]="displaydate"
    (ngModelChange) ="timeselected(null,$event)"
    [disabled]="disabled"
    [required]="required"

>
</med-datefield>
</div>

  <div class="timepicker col-6">
    <mat-form-field appearance="outline" class="example-full-width">
      <mat-label>{{ TimeLabel }}</mat-label>
      <input matInput placeholder="tijd" aria-label="24hr format" [disabled]="disabled" [required]="required"[(ngModel)]="displaytime" [ngxTimepicker]="toggleTimepicker" [format]="24" readonly/>
      <ngx-material-timepicker-toggle matSuffix [for]="toggleTimepicker"></ngx-material-timepicker-toggle>
      <ngx-material-timepicker [editableHintTmpl]="dialHint" #toggleTimepicker (timeChanged)="timeselected($event,null)" [theme]="medTheme" [enableKeyboardInput]="true" [minutesGap]="5"></ngx-material-timepicker>
      <ng-template #dialHint>
        <p style="color: #ffffff;">{{Hint}}</p>
    </ng-template>
    </mat-form-field>
</div>
</div>
</div>