import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import {
  CollectOrderItem,
  CollectOrderItemError,
  CollectOrderItemSuccess, LoadAllMembers, LoadAllMembersError, LoadAllMembersSuccess,
  LoadAllOrderItems,
  LoadAllOrderItemsSuccess,
  OrderActions,
  OrderActionTypes, SearchMembers, SearchMembersError
} from './order.actions';
import { Member, Order, OrderItem } from './order.model';
import { OrderService } from './order.service';

@Injectable()
export class OrderEffects {
  @Effect() loadOrders$ = this.actions$.pipe(
    ofType(OrderActionTypes.LoadAllMembers),
    switchMap((action: LoadAllMembers) =>
      this._orderService.getAll()
      .pipe(
        switchMap((members: Array<Member>) =>
          of(new LoadAllMembersSuccess(members))
        ),
        catchError(() => of(new LoadAllMembersError()))
      )
    )
  );

  @Effect() loadAllOrderItems$ = this.actions$.pipe(
    ofType(OrderActionTypes.LoadAllOrderItems),
    switchMap((action: LoadAllOrderItems) =>
      this._orderService.getAllOrderItems(action.payload)
      .pipe(
        switchMap((orderitems: Array<OrderItem>) =>
          of(new LoadAllOrderItemsSuccess(orderitems))
        ),
        catchError(() => of(new LoadAllMembersError()))
      )
    )
  );

  @Effect() searchOrders$ = this.actions$.pipe(
    ofType(OrderActionTypes.SearchMembers),
    switchMap((action: SearchMembers) =>
      this._orderService.getFiltered(action.searchterm)
      .pipe(
        switchMap((members: Array<Member>) =>
          of(new LoadAllMembersSuccess(members))
        ),
        catchError(() => of(new SearchMembersError()))
      )
    )
  );

  @Effect() collectOrderItem$ = this.actions$.pipe(
    ofType(OrderActionTypes.CollectOrderItem),
    switchMap((action: CollectOrderItem) =>
      this._orderService.collectOrderItem(action.orderItem)
      .pipe(
        switchMap((orderItem: OrderItem) =>
          of(new CollectOrderItemSuccess(orderItem))
        ),
        catchError(() => of(new CollectOrderItemError()))
      )
    )
  );

  @Effect({dispatch: false}) collectOrderItemSuccces$ = this.actions$.pipe(
    ofType(OrderActionTypes.CollectOrderItemSuccess),
    map((action: CollectOrderItemSuccess) => action.orderItem),
    tap(orderitem =>
      this._matSnackBar.open(
        `Order-item met id ${orderitem.id} is definitief opgehaald`,
        undefined,
        {
          duration: 2000
        }
      )
    )
  );

  constructor(
    private readonly actions$: Actions<OrderActions>,
    private readonly _orderService: OrderService,
    private readonly _matSnackBar: MatSnackBar
  ) {}
}
