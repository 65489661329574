import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isNullOrUndefined } from 'util';
import { OrderActions, OrderActionTypes } from './order.actions';
import { Member, OrderItem } from './order.model';

export const SzOrderFeatureName = 'SzOrder';

interface State {
  members: Array<Member>;
  orderItems: Array<OrderItem>;
  isInitializing: boolean;
  hasError: boolean;
  isLoading: boolean;
}

const initialState: State = {
  members: new Array<Member>(),
  orderItems: new Array<OrderItem>(),
  hasError: false,
  isInitializing: true,
  isLoading: false
};

export function reducer(state = initialState, action: OrderActions): State {
  switch (action.type) {
    case OrderActionTypes.LoadAllMembers:
      return { ...state, isInitializing: false, isLoading: true, hasError: false };
    case OrderActionTypes.LoadAllMembersSuccess:
      return { ...state, isLoading: false, hasError: false, members: action.payload };
    case OrderActionTypes.LoadAllMembersError:
      return { ...state, isInitializing: false, isLoading: false, hasError: true };

    case OrderActionTypes.SearchMembers:
      return { ...state, isInitializing: false, isLoading: true, hasError: false  };
    case OrderActionTypes.SearchMembersSuccess:
      return { ...state, isLoading: false, hasError: false, members: action.payload };
    case OrderActionTypes.SearchMembersError:
      return { ...state, isInitializing: false, isLoading: false, hasError: true };

    case OrderActionTypes.LoadAllOrderItems:
      return { ...state, isInitializing: false, isLoading: true, orderItems: new Array<OrderItem>(), hasError: false };
    case OrderActionTypes.LoadAllOrderItemsSuccess:
      return { ...state, isLoading: false, hasError: false, orderItems: action.orderItems };
    case OrderActionTypes.LoadAllOrderItemsError:
      return { ...state, isInitializing: false, isLoading: false, hasError: true };

    case OrderActionTypes.CollectOrderItem:
      return { ...state, isInitializing: false, isLoading: true, hasError: false  };
    case OrderActionTypes.CollectOrderItemSuccess:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        orderItems: state.orderItems.map(orderItem =>
          orderItem.id === action.orderItem.id ? {...orderItem, pickUpDate: action.orderItem.pickUpDate} : orderItem
        )
      };
    case OrderActionTypes.CollectOrderItemError:
      return { ...state, isInitializing: false, isLoading: false, hasError: true };
    default:
      return state;
  }
}

const featureSelector = createFeatureSelector(SzOrderFeatureName);

export const allOrdersInitializing = createSelector(
  featureSelector,
  (state: State) => state.isInitializing
);

export const hasErrorSelector = createSelector(
  featureSelector,
  (state: State) => state.hasError
);

export const isLoadingSelector = createSelector(
  featureSelector,
  (state: State) => state.isLoading
);

export const allMembersSelector = createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.members)) {
      return state.members;
    }

    return undefined;
  }
);

export const allOrderItemsSelector = createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.orderItems)) {
      return state.orderItems;
    }

    return undefined;
  }
);

export const notCollectedOrderItemsSelector = createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.orderItems)) {
      return state.orderItems.filter(item => isNullOrUndefined(item.pickUpDate));
    }

    return undefined;
  }
);

export const orderItemsFromOrderSelector = orderid => createSelector(
  featureSelector,
  (state: State) => {
    if (!isNullOrUndefined(state.orderItems)) {
      return state.orderItems.filter(orderitem => orderitem.orderId === Number(orderid));
    }

    return undefined;
  }
);

export const singleMemberSelector = memberid =>
  createSelector(
    featureSelector,
    (state: State) => {
      if (!isNullOrUndefined(state.members)) {
        return state.members.find(member => member.id === Number(memberid));
      }

      return undefined;
    }
  );
