import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ActivatedRoute } from '@angular/router';
import { supermarketNameSelector } from '@x-fund-apps/spaarze/data';

@Component({
  selector: 'spaarze-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  supermarketName$ = this.store.select(supermarketNameSelector);

  constructor(public dialog: MatDialog,
              private store: Store<any>) {}

  showTermsPopup(): void {
    this.dialog.open(TermsAndConditionsComponent);
  }

  showPrivacyPopup(): void {
    this.dialog.open(PrivacyPolicyComponent);
  }

}
