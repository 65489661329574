import { Action } from '@ngrx/store';
import { ProspectSupermarketPurl } from './prospect-supermarket.model';

export enum ProspectSupermarketActionTypes {
  LoadProspect = '[Prospect] Load Prospect',
  LoadProspectSuccess = '[Prospect] Load Prospect Success',
  LoadProspectError = '[Prospect] Load Prospect Error'
}

export class LoadProspect implements Action {
  readonly type = ProspectSupermarketActionTypes.LoadProspect;
  constructor(public payload: string) {}
}

export class LoadProspectSuccess implements Action {
  readonly type = ProspectSupermarketActionTypes.LoadProspectSuccess;
  constructor(public payload: ProspectSupermarketPurl) {}
}

export class LoadProspectError implements Action {
  readonly type = ProspectSupermarketActionTypes.LoadProspectError;
}

export type ProspectSupermarketActions =
  | LoadProspect
  | LoadProspectSuccess
  | LoadProspectError;
