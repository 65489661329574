import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MedAuthorizationLibModule } from '@x-fund-apps/med-authorization-lib';
import { MedFormsLibModule } from '@x-fund-apps/med-forms-lib';
import { SupermarketEffects, SupermarketService, szSupermarketReducer } from '@x-fund-apps/spaarze/data';
import { SpaarzeLayoutModule, ThemeService } from '@x-fund-apps/spaarze/layout';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { entityConfig } from './entity-metadata';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ImageService } from './shared/image.service';
import { SharedModule } from './shared/shared.module';
import { SupermarketDemoComponent } from './supermarket-demo/supermarket-demo.component';
import { RegisterEntrepreneurComponent } from './register/register-entrepreneur/register-entrepreneur.component';
import { MatRippleModule } from '@angular/material/core';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    HowItWorksComponent,
    ForgotPasswordComponent,
    SupermarketDemoComponent,
    RegisterEntrepreneurComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),

    // Setup ngRx store
    StoreModule.forRoot({
      router: routerReducer,
      SzSupermarket: szSupermarketReducer
    }),
    EffectsModule.forRoot([SupermarketEffects]),

    AppRoutingModule,
    // Connects RouterModule with StoreModule
    StoreRouterConnectingModule.forRoot(),

    MedAuthorizationLibModule,

    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false // Restrict extension to log-only mode
    }),
    EntityDataModule.forRoot(entityConfig),

    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        idsRegExp: new RegExp('^[A-z]+\\.[A-z0-9]+$')
      }
    }),

    SharedModule,
    MedFormsLibModule,
    MatRippleModule,
    SpaarzeLayoutModule
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: 'ENV',
      useValue: environment
    },
    ImageService,
    ThemeService,
    SupermarketService
  ]
})
export class AppModule {
  constructor(translate: TranslateService) {
    registerLocaleData(localeNl);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('nl');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('nl');
  }
}
