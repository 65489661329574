import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'spaarze-layout-footer-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {

  constructor(public dialogRef: MatDialogRef<PrivacyPolicyComponent>) {}

  close(): void {
    this.dialogRef.close();
  }

}
