<spaarze-layout-header [menuItems]="menu"
                       [supermarket]="supermarket$ | async"
                       [isAuthenticated]="false">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>
          Wachtwoord instellen
        </h1>
        <p>Stuur je e-mailadres en je ontvangt binnen enkele minuten een nieuw wachtwoord waarmee je weer kan inloggen.</p>

        <div class="row">
          <div class="col-12 col-md-9">
            <form name="form" #f="ngForm" novalidate class="center">
              <div >{{message}}</div>
              <med-textfield
                name="username"
                [(ngModel)]="username"
                Label="E-mailadres"
                appearance=""
                CssClass="spaarze-input"
                required
                email
              ></med-textfield>

              <button
                class="col spaarze-login-button"
                mat-ripple
                (click)="onSubmit()"
              >
                Versturen
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        class="d-none d-md-flex col-6 codes-image"
        [style.background-image]="
          'url(../../assets/images/pictures/shutterstock_481976119.jpg)'
        "
      ></div>
    </div>
  </div>
</spaarze-layout-header>
