<h1 mat-dialog-title>Privacy Policy</h1>
<div class="close" (click)="close()"></div>

<div class="content">
  <p>
    SpaarZe.nl respecteert de privacy van alle gebruikers van haar site en draagt er zorg voor dat de persoonlijke informatie die u ons verschaft vertrouwelijk wordt behandeld. Wij hebben passende technische en organisatorische maatregelen genomen om uw persoonsgegevens te beveiligen tegen verlies, of tegen enige vorm van onrechtmatige verwerking. Wij gebruiken uw gegevens om de actie en de bestellingen zo goed, snel en gemakkelijk mogelijk te laten verlopen. Voor het overige zullen wij gegevens uitsluitend gebruiken met uw toestemming. SpaarZe.nl zal uw persoonlijke gegevens niet aan derden verkopen en zal deze uitsluitend aan derden ter beschikking stellen die zijn betrokken bij het uitvoeren van de actie of uw bestelling.
  </p>
  <h1>
    SpaarZe.nl gebruikt de verzamelde gegevens om haar klanten de volgende diensten te leveren:
  </h1>
  <ul>
    <li>Als u als organisatie deelneemt aan SpaarZe hebben we de gegevens van uw organisatie nodig om een goed actieverloop te kunnen garanderen en betrokken spaarders en u van het verloop van de actie op de hoogte te houden.</li>
    <li>Voor het vrijblijvend openen van de SpaarZe-shop voert de spaarder gegevens in (naam, adres, email-adres, telefoon, naam + email medespaarders). De organisatie verstrekt geen enkele gegevens over spaarders. Deelname is voor spaarders geheel vrijblijvend. Spaarders bepalen zelf OF en wie zij uitnodigen om mee te sparen. Gegevens van de genodigden zullen uitsluitend gebruikt worden om te informeren over de SpaarZe-shop per email. Genodigden ontvangen maximaal 2 emails uit naam van de spaarder. Een voorbeeld van de uitnodiging is zichtbaar in de marketingmodule van de SpaarZe-shop.</li>
    <li>Als de spaarder een bestelling plaatst, hebben we naam, e-mailadres, afleveradres en betaalgegevens nodig om uw bestelling uit te voeren en u van het verloop daarvan op de hoogte te houden. Voor de afhandeling van betalingen maakt SpaarZe gebruik van Ingenico (voorheen Ogone) als betalingsprovider (marktleider in het verwerken en correct afhandelen van online betalingen).</li>
    <li>Om het winkelen bij SpaarZe.nl zo aangenaam mogelijk te laten zijn, slaan wij met uw toestemming uw persoonlijke gegevens en de gegevens met betrekking tot uw bestelling en het gebruik van onze diensten op.</li>
    <li>Met uw toestemming gebruiken wij uw gegevens om u te informeren over de ontwikkeling van de website en over speciale aanbiedingen en acties. Als u hier niet langer prijs op stelt, kunt u ons dat laten weten door een e-mail te sturen naar info@SpaarZe.nl, of te bellen met onze klantenservice. Daarnaast kunt u zich onderaan iedere email die u van ons ontvangt afmelden via een afmeldlink.</li>
    <li>Gegevens van organisaties en spaarders worden bewaard zolang noodzakelijk wordt geacht voor voornamelijk reclamaties, nabestellingen en herdruk.</li>
    <li>Op verzoek kunnen wij u inzage geven in de van u opgeslagen gegevens en kunt u ten alle tijden verzoeken tot rectificatie of het wissen van uw persoonsgegevens.</li>
    <li>Gegevens over het gebruik van onze site en de feedback die we krijgen van onze bezoekers helpen ons om onze site verder te ontwikkelen en te verbeteren.</li>
  </ul>

  <h1>SpaarZe.nl verkoopt uw gegevens niet</h1>
  <p>
    SpaarZe.nl zal uw persoonlijke gegevens niet aan derden verkopen en zal deze uitsluitend aan derden ter beschikking stellen die zijn betrokken bij het uitvoeren van uw bestelling. Onze werknemers en door ons ingeschakelde derden zijn verplicht om de vertrouwelijkheid van uw gegevens te respecteren.
  </p>

  <h1>Gebruik van cookies bij SpaarZe.nl</h1>
  <p>
    Cookies zijn kleine tekstbestanden die bij het bezoeken van een website naar de computer worden gestuurd. Op onze website wordt gebruik gemaakt van verschillende cookies. Het belangrijkste gebruik van cookies bij SpaarZe.nl is om ervoor te zorgen dat het bestelproces correct kan verlopen. Verder worden er cookies gebruikt voor bijhouden van statistieken over het gebruik van onze website. Dit om u in de toekomst nog beter van dienst te kunnen zijn. Met het gebruik van onze website geeft u toestemming voor het gebruik van de cookies. Cookies kunt u alleen zelf verwijderen omdat deze op uw computer zijn opgeslagen. Uitleg over het aanpassen van de instellingen van uw browser is te vinden onder Help in de menubalk van de meest gebruikte browsers.
  </p>

  <p>
    Indien u nog vragen mocht hebben over de Privacy Policy van SpaarZe.nl, dan kunt u een e-mail sturen naar info@SpaarZe.nl of contact opnemen met 0499-392293. In geval wijziging van onze Privacy Policy nodig mocht zijn, dan vindt u op deze pagina altijd de meest recente informatie.
  </p>
</div>
