import { Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
  themeWrapper = document.querySelector('body');
  currentTheme: ThemeModel;

  private ah: ThemeModel = {
    font: '\'Fira Sans\', sans-serif',
    primaryColor: '#003147',
    primarySubHeaderColor: '#85B0C2',
    primaryBackgroundColor: '#D1F1FF',

    fontColor: '#004E71',
    fontColorLight: '#85B0C2',
    fontColorAlt: '#418752',

    accentColor: '#FF6624',
    accentTextColor: '#FFF',
    accentBackgroundColor: '#FFE4D8',

    footerColor: '#262626',

    savingsColor: '#19CA44',
    savingsTextColor: '#ffffff',
    savingsBackgroundColor: '#DEFFE6',

    secondaryColor: '#004E71',
    secondaryBackgroundColor: '#DEFFE6',

    backdropColor: '#095678'
  };

  private jumbo: ThemeModel = {
    font: '\'thesans\', sans-serif',
    primaryColor: '#003147',
    primarySubHeaderColor: '#85B0C2',
    primaryBackgroundColor: '#F2F2F2',

    fontColor: '#262625',
    fontColorLight: '#2f2f2e',
    fontColorAlt: '#003147',

    accentColor: '#FDC411',
    accentTextColor: '#FFF',
    accentBackgroundColor: '#fef3cf',

    footerColor: '#191919',

    savingsColor: '#FDC411',
    savingsTextColor: '#ffffff',
    savingsBackgroundColor: '#F2F2F2',

    secondaryColor: '#262625',
    secondaryBackgroundColor: '#FDC411',

    backdropColor: 'rgba(253, 196, 17, .9)'
  };

  private plus: ThemeModel = {
    font: '\'gotham\', tahoma, arial, helvetica',
    primaryColor: '#003147',
    primarySubHeaderColor: '#004c6f',
    primaryBackgroundColor: '#F5F0ED',

    fontColor: '#212121',
    fontColorLight: '#bbbbba',
    fontColorAlt: '#217646',

    accentColor: '#217646',
    accentTextColor: '#FFF',
    accentBackgroundColor: '#F5F0ED',

    footerColor: '#333333',

    savingsColor: '#EC7404',
    savingsTextColor: '#ffffff',
    savingsBackgroundColor: '#F5F0ED',

    secondaryColor: '#EC7404',
    secondaryBackgroundColor: '#F5F0ED',

    backdropColor: '#095678'
  };

  private coop: ThemeModel = {
    font: '\'Museo\', sans-serif',
    primaryColor: '#003147',
    primarySubHeaderColor: '#85B0C2',
    primaryBackgroundColor: '#F3EFEB',

    fontColor: '#070707',
    fontColorLight: '#bbbbba',
    fontColorAlt: '#070707',

    accentColor: '#F79300',
    accentTextColor: '#FFF',
    accentBackgroundColor: '#F3EFEB',

    footerColor: '#F79300',

    savingsColor: '#EC7404',
    savingsTextColor: '#ffffff',
    savingsBackgroundColor: '#F3EFEB',

    secondaryColor: '#EC7404',
    secondaryBackgroundColor: '#F3EFEB',

    backdropColor: 'rgba(247, 147, 0, .9)'
  };

  SetThemeForBrand(brand: string): void {
    switch (brand) {
      case  'Albert Heijn':
        this.SetTheme(this.ah);
        break;
      case  'Jumbo':
        this.SetTheme(this.jumbo);
        break;
      case  'Plus':
        this.SetTheme(this.plus);
        break;
      case  'Coop':
        this.SetTheme(this.coop);
        break;
      default:
        this.SetTheme(this.ah);
    }
  }

  private SetTheme(theme: ThemeModel): void {
    this.currentTheme = theme;
    this.themeWrapper.style.setProperty('--font', theme.font);
    this.themeWrapper.style.setProperty('--primary-color', theme.primaryColor);
    this.themeWrapper.style.setProperty('--primary-sub-header-color', theme.primarySubHeaderColor);
    this.themeWrapper.style.setProperty('--primary-background-color', theme.primaryBackgroundColor);

    this.themeWrapper.style.setProperty('--font-color', theme.fontColor);
    this.themeWrapper.style.setProperty('--font-color-light', theme.fontColorLight);
    this.themeWrapper.style.setProperty('--font-color-alt', theme.fontColorAlt);

    this.themeWrapper.style.setProperty('--accent-color', theme.accentColor);
    this.themeWrapper.style.setProperty('--accent-text-color', theme.accentTextColor);
    this.themeWrapper.style.setProperty('--accent-background-color', theme.accentBackgroundColor);

    this.themeWrapper.style.setProperty('--footer-color', theme.footerColor);

    this.themeWrapper.style.setProperty('--savings-color', theme.savingsColor);
    this.themeWrapper.style.setProperty('--savings-text-color', theme.savingsTextColor);
    this.themeWrapper.style.setProperty('--savings-color-background-color', theme.savingsBackgroundColor);

    this.themeWrapper.style.setProperty('--secondary-color', theme.secondaryColor);
    this.themeWrapper.style.setProperty('--secondary-background-color', theme.secondaryBackgroundColor);
    this.themeWrapper.style.setProperty('--backdrop-color', theme.backdropColor);
  }
}

export class ThemeModel {
  font: string;

  primaryColor: string;
  primarySubHeaderColor: string;
  primaryBackgroundColor: string;

  fontColor: string;
  fontColorLight: string;
  fontColorAlt: string;

  accentColor: string;
  accentTextColor: string;
  accentBackgroundColor: string;

  footerColor: string;

  savingsColor: string;
  savingsTextColor: string;
  savingsBackgroundColor: string;

  secondaryColor: string;
  secondaryBackgroundColor: string;

  backdropColor: string;
}
