import { Component, Input, OnInit } from '@angular/core';
import { BonusProduct } from '@x-fund-apps/spaarze/data';

@Component({
  selector: 'spaarze-layout-bonus-product',
  templateUrl: './bonus-product.component.html',
  styleUrls: ['./bonus-product.component.scss']
})
export class BonusProductComponent {
  @Input() bonusProducts: Array<BonusProduct>;
  @Input() organisationId: number;
}
