<spaarze-layout-header [menuItems]="menu"
                       [supermarket]="supermarket$ | async"
                       [isAuthenticated]="false">
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Registreer</h1>
        <p>
          Maak een account aan om uw codes in te voeren en te verzilveren voor geweldige SpaarZe-acties.
        </p>

        <div class="row">
          <div class="col col-md-9">
            <form name="form" #f="ngForm" class="center">
              <med-textfield
                name="name"
                [(ngModel)]="registerModel.name"
                Label="Naam"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="email"
                [(ngModel)]="registerModel.email"
                Label="E-mailadres (is eveneens uw gebruikersnaam)"
                appearance=""
                CssClass="spaarze-input"
                required
                email
              ></med-textfield>

              <med-textfield
                name="password"
                [(ngModel)]="registerModel.password"
                type="password"
                Label="Wachtwoord"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-textfield
                name="passwordRepeat"
                [(ngModel)]="registerModel.passwordRepeat"
                type="password"
                Label="Herhaal wachtwoord"
                appearance=""
                CssClass="spaarze-input"
                required
              ></med-textfield>

              <med-checkbox
                name="policy"
                Label="Ja, ik accepteer de <a href='/assets/docs/AlgemeneVoorwaardenSpaarze.pdf' class='link'  target='blank'>algemene-</a> en "
                LabelLink="privacy voorwaarden"
                [(ngModel)]="termsBool"
                appearance=""
                CssClass="spaarze-checkbox"
                (linkClicked)="showTermsPopup()"
                required
              ></med-checkbox>

              <div
                [matTooltip]="isModelValid(registerModel).errors"
                [matTooltipDisabled]="isModelValid(registerModel).valid"
              >
                <button
                  class="col spaarze-login-button"
                  mat-ripple
                  (click)="onSubmit()"
                  [disabled]="
                    (!f.form.valid || !isModelValid(registerModel).valid)
                  "
                >
                  Registreer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        class="d-none d-md-flex col-6 codes-image"
        [style.background-image]=getHomeImageUrl()
      ></div>
    </div>
  </div>
</spaarze-layout-header>
<div class="loading-screen-wrapper" *ngIf="(isLoading$ | async)">
  <div class="loading-screen-spinner">
    <mat-spinner></mat-spinner>
  </div>
</div>
