import { GenericDataServiceProperties } from '@x-fund-apps/med-shared';

export class SzProduct {
  id: number;
  name: string;
  logo: string;
  supermarketId: number;
  price: string;
  collectMessage: string;
  description: string;
  priceCodes: number;
  productGroupId: number;
  startDate: Date;
  endDate: Date;
  stock: number;
  stockLeft: number;
  significant: boolean;
  collectStartDate: Date;
  collectEndDate: Date;
  mailPromotion: boolean;
  mailOnActionStart: boolean;

  hasCheque: boolean;
  chequeValue: string;
  hasText: boolean;

}
export const szProductUpdateFilter = (product: SzProduct) => {
  delete product.stockLeft;
  return product;
};

export const SZ_PRODUCT_PROPS: GenericDataServiceProperties = {
  singlename: 'SzProduct',
  pluralname: 'SzProducts',
  fields: `
  id
  name
  significant
  supermarketId
  price
  priceCodes
  startDate
  endDate
  stock
  stockLeft
  collectStartDate
  collectEndDate
  collectMessage
  mailPromotion
  mailOnActionStart
  description
  logo
  productGroupId
  hasCheque
  chequeValue
  hasText`
};
