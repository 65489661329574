import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isNullOrUndefined } from 'util';
import { CustomImageSettingUploadServiceInterface } from './CustomImageSettingUploadServiceInterface';

@Component({
  selector: 'med-customimagesetting-upload',
  templateUrl: './med-customimagesetting-upload.component.html',
  styleUrls: ['./med-customimagesetting-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MedCustomimagesettingUploadComponent),
      multi: true
    }
  ]
})
export class MedCustomimagesettingUploadComponent
  implements ControlValueAccessor {
  @Input() name: string;
  @Input() project: string;

  @Input() Label: string;
  @Input() hint: string;
  @Input() cssClass = 'col';
  @Input() infoImageUrl: string;

  @Input() disabled: boolean;

  @Input() imageUploadService: CustomImageSettingUploadServiceInterface;
  @Input() required = false;

  previewUrl: any = undefined;
  fileUploadProgress: string = undefined;
  // tslint:disable-next-line
  customImageSetting: CustomImageSetting = new CustomImageSetting();
  isLoading = false;

  private val: number;
  get value(): number {
    return this.val;
  }
  set value(val: number) {
    if (val !== undefined && this.val !== val && val !== null) {
      this.val = val;
      this.onChange(val);
      this.onTouch(val);
      this.getCustomImageSetting(val);
    }
  }

  private fileData: File = undefined;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private snackbar: MatSnackBar) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  fileSelected(fileInput: any): void {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
    this.submit();
  }
  preview(): void {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) === undefined || mimeType.match(/application\/*/) === undefined) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = _event => {
      if (mimeType.match(/application\/*/)) {
        this.loadPreviewMimeType(mimeType);
      } else {
        this.previewUrl = reader.result;
      }
      this.isLoading = false;
      // this.disabled = false;
    };
  }

  // Submit to file server and save into database
  submit(): void {
    this.imageUploadService
      .postFile(this.project, this.name, this.fileData)
      .subscribe((events: HttpEvent<Object>) => {
        if (events.type === HttpEventType.UploadProgress) {
          this.fileUploadProgress =
            Math.round((events.loaded / events.total) * 100) + '%';
        } else if (events.type === HttpEventType.Response) {
          this.fileUploadProgress = '';
          this.customImageSetting.fileUrl = (events.body as any).name;
          this.saveCustomImageSetting(this.customImageSetting);
        }
      });
  }

  getCustomImageSetting(id): void {
    this.imageUploadService.getCustomImageSetting(id).subscribe(data => {
      this.loadImage(data);
      this.isLoading = true;
      // this.disabled = true;
    });
  }

  saveCustomImageSetting(customImageSetting: CustomImageSetting): void {
    this.imageUploadService.addCustomImageSetting(customImageSetting).subscribe(
      response => {
        this.customImageSetting = response;
        this.value = this.customImageSetting.id;
      },
      error => {
        this.snackbar.open(
          `Er is een fout opgetreden met het opslaan in de database. Controleer de code
              en probeer het opnieuw.`,
          'OK',
          {
            verticalPosition: 'top',
            duration: 5000
          }
        );
      }
    );
  }

  remove(): void {

    this.val = undefined;
    this.onChange(undefined);
    this.onTouch(undefined);

    this.value = undefined;
    this.fileData = undefined;
    this.previewUrl = undefined;
  }

  loadImage(val: CustomImageSetting): void {
    if (!isNullOrUndefined(val)) {
      this.imageUploadService
        .getFile(this.project, val.fileUrl)
        .subscribe((res: File) => {
          this.fileData = res;
          this.preview();
        });
    }
  }

  // If mimetype is NOT an image, than load static image of the mimetype from assets
  loadPreviewMimeType(mimeType: string): void {
    if (mimeType.match(/application\/pdf/)) {
      this.previewUrl = '/assets/images/mimeTypes/pdf.png';
    } else if (mimeType.match(/application\/msword/)) {
      this.previewUrl = '/assets/images/mimeTypes/word.png';
    }
  }
}

export class CustomImageSetting {
  id: number;
  description: string;
  approved: string;
  redirectUrl: number;
  fileUrl: string;
}
