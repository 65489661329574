import { HttpErrorResponse } from '@angular/common/http';

export class GraphQlResponse {
  data: object;
  errors: Array<any>;
}

export class DataWrapperModel<T> {
  me: T;
}



export function ThrowGraphQlError(url: string, error: Array<any>): void {
  throw new HttpErrorResponse({
    error,
    status: 500,
    statusText: 'GraphQlError',
    url
  });
}
