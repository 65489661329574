<label>{{Label}}</label>
<mat-radio-group
    [class]="CssClass"
    [(ngModel)]="inputValue"
    (ngModelChange)="inputValueChange.emit(inputValue)"
    [required]="required">
  <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option.id">
    {{option.name}}
  </mat-radio-button>
</mat-radio-group>
