import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule, Inject } from '@angular/core';
import { EntityDataService } from '@ngrx/data';
import { GenericDataService } from '@x-fund-apps/med-shared';
import { Entrepreneur, ENTREPRENEUR_PROPS } from './entrepreneur.model';

@NgModule({
  imports: [CommonModule]
})
export class EntrepreneurSupermarketStoreModule {
  constructor(
    httpClient: HttpClient,
    entityDataService: EntityDataService,
    @Inject('ENV') private readonly environment
  ) {
    entityDataService.registerService(
      'Entrepreneur',
      new GenericDataService<Entrepreneur>(
        httpClient,
        ENTREPRENEUR_PROPS,
        this.environment.urls.supermarket.graph
      )
    );
  }
}
